//
// Template Styles
// --------------------------------------------------

body.page-template-template-homepage #main {
    padding-top: 121px; // Header height
    z-index: 15;
}

body.single-offer,
body.single-treatment {

    #popup-book-treatment {

        .treatments-wrapper {
            display: none !important;
        }
    }
}

#page-header {
    margin-top: 179px; //header height
    padding-top: $massive;
  
    @media (max-width:1199px) {
        margin-top: 175px;
    }
  
    @media (max-width:991px) {
        margin-top: 168px;
    }
  
    @media (max-width:767px) {
        margin-top: 72px;
    }

    .slick-slider {
        margin-bottom: 0 !important;
    }

    &.standard-header {

        .caption {

            a {
                font-weight: bold;
                color: $color-black;
            }
        }
    }
}

.filters {
    font-size: 1.8rem;
    line-height: normal;
    font-family: $font-title;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $huge;

    .filter {
        font-size: inherit;
        line-height: inherit;
        padding: 0;
        background-color: transparent;
        border: none;
        transition: all .2s linear;
        
        &.search-btn {
            font-family: $fontawesome-regular;
            font-size: 1.3rem;
            font-weight: bold;
            width: fit-content;
        }
    }

    .search-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: .5rem;
        border-bottom: 1px solid transparent;

        &.is-open {
            border-color: $color-white;

            .search-btn {
                opacity: 1 !important;
            }

            form {
                &#form-search {
                    width: auto;
                    transform: scaleX(1);
                    overflow: visible;
                }
            }
        }

        @media (max-width:991px) {
            margin-bottom: $small;
            width: 100%;
            border-color: $color-white;

            .search-btn {
                opacity: 1 !important;
            }

            form {
                &#form-search {
                    transform: scaleX(1) !important;
                    overflow: visible !important;
                }
            }
        }

        form {
            &#form-search {
                margin: 0;
                margin-left: .8rem;
                width: 0;
                overflow: hidden;
                transform: scaleX(0);
                transition: all .2s linear;
                transform-origin: left;

                input {
                    padding: 0;
                    border: none !important;
                    color: $color-white;
                    font-size: $small;
                    min-width: 25rem;

                    &::placeholder {
                        opacity: .8;
                    }
                }

                @media (max-width:991px) {
                    width: calc(100% - 45px - 1rem);

                    input {
                        width: 100%;
                    }
                }
            }
        }
    }


    @media (max-width:991px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        margin-bottom: 0 !important;

        button.filter:not(.search-btn) {
            display: none;
        }

        .filter {
            width: 100%;
            text-align: left;
            justify-content: flex-start;
            padding: .5rem .6rem;
            margin: 0;
        }
    }
}

.filter-title {
    font-size: .8rem;
    line-height: normal;
    display: none;
    font-family: $font-base;
    font-weight: 400;
    margin-bottom: .5rem;

    @media (max-width:991px) {
        display: block;
    }
}

.filters-mobile {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid $color-white;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    font-size: 1rem;
    line-height: normal;
    font-family: $font-title;
    font-weight: 500;
    
    &.is-open {
        overflow: visible;

        span.title {
            &::after {
                transform: rotate(180deg);
            }
        }

        ul.is-showed,
        ul.filters-list.is-showed {
            visibility: visible;
            display: initial !important;
        }

        &.type {
            ul {
                visibility: visible;
                display: initial;
            }
        }
    }

    span.title {
        padding: .5rem .6rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &::after {
            content: "\f063";
            font-family: $fontawesome-regular;
            font-size: $small;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        position: absolute;
        width: calc(100% + 2px);
        left: -1px;
        z-index: 1;
        background-color: $color-black;
        border: 1px solid $color-white;
        border-top: none;
        margin-top: 1px;
        display: none;
        visibility: hidden;

        li {
            padding: .5rem .6rem;
            opacity: 1 !important;
            font-size: inherit;

            &.is-active,
            &:hover {
                color: $color-black !important;
                background-color: $color-white;
            }
        }
    }

    @media (max-width:991px) {
        display: block !important;
        margin-bottom: $small;

        span.title {
            display: flex;
        }
    }
}

.filters-list {
    font-size: $big;
    line-height: normal;
    font-family: $font-title;
    font-weight: 500;
    padding: 0;
    margin: 0;
    list-style: none;
    justify-content: flex-start;
    align-items: flex-start;
    gap: $huge;
    display: none;
    visibility: hidden;
    width: fit-content;

    &.is-showed {
        display: flex;
        visibility: visible;
        flex-wrap: wrap;
        row-gap: 1.2rem;
    }

    li {
        cursor: pointer;
        transition: all .2s linear;
        opacity: .5;

        &.is-active {
            opacity: 1;
        }
    }

    &:hover {

        li {
            opacity: .5;

            &:hover {
                opacity: 1;
            }
        }
    }

    @media (max-width:991px) {
        font-size: 1rem !important;

        &.desktop {
            display: none;
            visibility: hidden;
        }
    }
}

.filters {
    &.treatments,
    &.team,
    &.products-filters {

        .filter {
            opacity: .5;

            &.is-active,
            &:hover {
                opacity: 1;
                color: $color-black;
            }
        }

        .search-container {
            &.is-open {
                border-color: $color-black;
            }

            form {
                &#form-search {
                    input {
                        color: $color-black;
                    }
                }
            }

            @media (max-width:991px) {
                border-color: $color-black;
            }
        }
    }
}

.member-block {

    .member-img-slider {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

#gform_4 {

    select {
        width: calc(50% - 12px);
        height: $massive;
        padding: 0.5rem 1rem;
        background-color: transparent;
        color: inherit;
        border-color: $color-white;
        background-image: url('../images/down-arrow-white.svg');
        background-position-x: 97%;

        &:focus-visible {
            outline-color: $color-white;
        }

        @media (max-width:767px) {
            width: 100%;
        }
    }

    label,
    .gfield_label {
        color: $color-white;
    }

    input:not([type="submit"]),
    textarea {
        color: $color-white;
        border-color: $color-white;

        &:focus {
            border-bottom: 2px solid $color-white !important;
        }
    }

    .gfield_checkbox {

        .gchoice {

            label {
                &::before {
                    border-color: $color-white;
                }
            }

            .gfield-choice-input:checked~label {

                &::before {
                    background-color: $color-white !important;

                }
            }
        }
    }

   .gform_footer {

    input {
        &:hover {
            border-color: white;
        }
    }
   }
}

.popup {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    right: -110vw;
    z-index: 22;
    transition: all .2s linear;
    
    &.is-open {
        right: 0;
    }

    .popup-container {
        height: 100%;
        max-height: 100vdh;
        overflow-x: hidden;
        overflow-y: scroll;
        width: 50vw;
        margin-left: auto;
        padding: $large $massive;
    }

    @media (max-width:1440px) {

        .popup-container {
            width: 70vw;
        }
    }

    @media (max-width:1199px) {
        
        .popup-container {
            width: 80vw;
        }
    }

    @media (max-width:767px) {

        .popup-container {
            width: 100vw;
        }
    }

    @media (max-width:576px) {

        .popup-container {
            padding: $big $large;
        }
    }
}

#popup-treatment-finder {

    select {
        color: $color-white;
        background-color: transparent;
        margin-bottom: $huge;
        border-color: $color-white;
        height: $massive;
        padding: .5rem 1rem;
        cursor: pointer;
        background-image: url('../images/down-arrow-white.svg');
        //background-position-x: 97%;

        &:focus-visible {
            outline-color: $color-white;
        }

        &.is-active {
            color: $color-black;
            background-color: $color-white;
        }

    }

    h3.large {
        margin-bottom: $medium;
    }

    .btn-primary.reverse {
        margin-left: auto;
    }
}

#popup-contact,
#popup-treatment-finder {

    .btn-close-popup {
        font-size: $large;
        padding: 0;
        background-color: transparent;
        color: $color-white;
        border: none;
        margin-left: auto;
        margin-bottom: 1rem;
    }

    h2.massive {
        margin-bottom: $gigantic;
        text-transform: none;
    }

    .popup-container {
        background-color: $color-black;
        color: $color-white;
    }
}

#newsletter-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $big;
    margin-bottom: 7.8rem;

    h2 {
        max-width: 83%;
    }

    div {
        max-width: 66%;
    }

    @media (max-width:1441px) {
        h2, div {
            max-width: 100%;
        }
    }

    @media (max-width:767px) {
        grid-template-columns: 1fr;
        gap: $large;
    }
}

.offer-block {

    .content {
        flex-direction: column;
        align-items: flex-start !important;
        padding: $large $big !important;

        h3 {
            margin-bottom: $regular
        }

        p {
            margin: 0;
        }

        .validity {
            margin-bottom: $big;
            font-family: $font-title;
            font-weight: 500;
        }
    }
}

#page-press {

    .press-grid {
        margin-bottom: 7.8rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2.2rem;
        row-gap: 3.5rem;

        @media (max-width:767px) {
            grid-template-columns: 1fr;
        }
    }

    .old {
        margin-bottom: 7.8rem;

        h2 {
            margin-bottom: 2.2rem;
        }

        ul {
            padding: 0;
            margin: 0;
            margin-left: 20px;

            li {
                line-height: 1.5rem;
            }

            a {
                color: $color-black;
                text-decoration: underline;
            }
        }
    }
}

.job-block {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    gap: $big;
    border: 1px solid $color-black;
    color: $color-black;
    background-color: transparent;
    transition: all .2s linear;
    padding: $big;
    min-height: 11.7rem;

    &:hover {
        border-color: $color-green;
        background-color: $color-green;
    }

    &.apply {
        background-color: $color-black;
        color: $color-white;
        gap: 0;

        &:hover {
            background-color: $color-green;
            color: $color-black;

            .btn-primary {
                background-color: transparent !important;
                border-color: $color-black !important;
                color: inherit !important;
            }
        }

        .btn-primary {
            margin-left: auto;
        }
    }

    .infos {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: $massive;

        .cities {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            max-width: 65%;
        }
    }

    h2 {
        width: 100%;
        text-transform: none;
    }

    @media (max-width:576px) {
        min-height: 8rem;
        gap: 1rem;

        .infos {
            row-gap: .8rem;
        }

        &.apply {

            .btn-primary {
                display: none;
            }
        }
    }
}

#page-jobs {

    .jobs-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
        row-gap: 2.2rem;

        @media (min-width:1920px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width:767px) {
            row-gap: $large;
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:576px) {
            grid-template-columns: 1fr;
        }
    }

    #apply-section {
        margin-top: 7.5rem;
        margin-bottom: 4rem;
        width: 100%;
        max-width: 50%;

        @media (max-width:1199px) {
            max-width: 80%;
        }

        @media (max-width:991px) {
            max-width: 100%;
            margin-top: 4rem;
        }

        h3 {
            margin-bottom: 2rem;
        }

        .form .gform_fields{
            column-gap: 2.5rem;
            row-gap: 2rem;
        }
        .gform_button_select_files{
            flex-direction: column-reverse;
        }
        .validation_message{
            position: relative;
            top: -25px;
            margin: 35px 0 20px;
        }

        .gform_footer {
            input {
                @media(max-width:576px) {
                    width: 100%;
                }
            }
        }
    }
}

#page-team {

    .filters {
        flex-wrap: wrap;
        row-gap: $big;

        @media (max-width:991px) {
            row-gap: 0;
        }

        >div:not(.filters-mobile) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: $huge;
            row-gap: $big;
            width: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            padding-bottom: 10px;

            /* largeur poignée */
            &::-webkit-scrollbar {
                height: 6px;
                //display: none;
            }
            
            /* background */
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            
            /* Poignée (thumb) */
            &::-webkit-scrollbar-thumb {
                background: #b3b3b3;
                transition: all .2s linear;
                border-radius: 8px;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                background: $color-black;
                //Firefox + Edge
                scrollbar-color: transparent $color-black;
            }

            @media (max-width:991px) {
                flex-direction: column;
                align-items: flex-start;
                overflow: visible;
                gap: 0;
                padding-bottom: 0;
            }
        }
    }

    .filters-mobile {
        border-color: $color-black;

        ul {
            background-color: white;
            border-color: black;

            li {
                &.is-active,
                &:hover {
                    background-color: $color-black;
                    color: $color-white !important;
                }
            }
        }

        &.is-active {

            .title {
                color: $color-white;
                background-color: $color-black;
            }
        }

        &.is-open {

            ul {
                visibility: visible;
                display: initial !important;
            }
        }
    }

    .team-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
        margin-top: $gigantic;

        #empty-msg {
            grid-column: span 4;
        }

        @media (max-width:1199px) {
            grid-template-columns: repeat(3, 1fr);

            #empty-msg {
                grid-column: span 3;
            }
        }

        @media (max-width:991px) {
            gap: $big;
            grid-template-columns: repeat(2, 1fr);
            margin-top: $massive;

            #empty-msg {
                grid-column: span 2;
            }
        }

        @media (max-width:576px) {
            grid-template-columns: 1fr;

            #empty-msg {
                grid-column: auto;
            }
        }
    }
}

.clinics-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $large;
    row-gap: $massive;
    margin-top: 5.5rem;

    #empty-msg {
        grid-column: span 3;
    }

    @media (min-width:1920px) {
        grid-template-columns: repeat(4, 1fr);

        #empty-msg {
            grid-column: span 4;
        }
    }

    @media (max-width:991px) {
        margin-top: $massive;
    }

    @media (max-width:767px) {
        row-gap: $large;
        grid-template-columns: repeat(2, 1fr);

        #empty-msg {
            grid-column: span 2;
        }
    }

    @media (max-width:576px) {
        grid-template-columns: 1fr;

        #empty-msg {
            grid-column: auto;
        }
    }
}

#page-clinics {

    .filters {

        .btn-primary {
            margin-left: auto;
            gap: .5rem;
            white-space: nowrap;

            &::after {
                content: "\f061";
                font-family: $fontawesome-regular;
                color: inherit;
                font-size: .75rem;
                line-height: normal;
                transition: $transition-base;
            }

            @media (max-width:991px) {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
    }

    .filters-mobile {
        border-color: $color-black;

        ul {
            background-color: white;
            border-color: black;

            li {
                &.is-active,
                &:hover {
                    background-color: $color-black;
                    color: $color-white !important;
                }
            }
        }

        &.is-open {

            ul {
                visibility: visible;
                display: initial !important;
            }
        }
    }

    #pillars-section {
        margin-top: 8.7rem;
    }
}

#page-treatments,
.treatment-taxonomy-page,
#single-pillar {
    position: relative;

    .taxonomies-content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
        
        .btn-primary {
            margin-top: $gigantic;
        }
    }

    .buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: $huge;
    }

    .filters {
        margin-bottom: $big;
    }

    .filters-mobile {
        border-color: $color-black;

        ul {
            background-color: white;
            border-color: black;

            li {
                &.is-active,
                &:hover {
                    background-color: $color-black;
                    color: $color-white !important;
                }
            }
        }
    }

    .img-block{
        &.treatment {
            flex-direction: column;

            .img {
                height: 17rem;
                max-width: 100%;
            }

            @media (max-width:767px) {
                gap: .5rem;
            }
        }
    }

    .treatments-grid {
        margin-top: $gigantic;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: $large;
        row-gap: $gigantic;

        #empty-msg {
            grid-column: span 3;
        }

        @media (min-width:1920px) {
            grid-template-columns: repeat(4, 1fr);

            #empty-msg {
                grid-column: span 4;
            }
        }

        @media (max-width:767px) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: $large;

            #empty-msg {
                grid-column: span 2;
            }
        }
    }
}

#single-pillar {

    .treatments-grid {
        margin-top: $gigantic;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: $large;
        row-gap: $gigantic;

        @media (min-width:1920px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width:767px) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: $large;
        }
    }
}

#single-treatment{

    section {
        scroll-margin-top: 150px;
    }

    .grid{
        display: flex;
        gap: $big;
        .side-nav{
            width: 30%;
            position: sticky;
            position: -webkit-sticky;
            top: 175px;
            left: 0;
            height: fit-content;

            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: $small;
                font-weight: 500;
                font-family: $font-title;

                a {
                    color: $color-black;
                    opacity: .3;

                    &.is-active,
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        .page-content{
            width: 70%;
            .section-title{
                padding-bottom: 4.25rem;
                text-transform: inherit;
            }
            #rates{
                padding-bottom: 5rem;
                .rates-icons{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    padding-bottom: 4.5rem;
                    gap: 2rem;
                    .info-block{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        row-gap: 0.625rem;
                        h3{
                            font-weight: 600;
                        }
                        .icon{
                            width: 100%;
                            img{
                                width: 3.438rem;
                                height: 3.438rem;
                            }
                            margin: 0;
                        }
                    }    
                }

                .estimate {
                    font-weight: 500;
                    font-family: $font-title;
                }

                .estimate,
                .membership {
                    margin-bottom: 2.5rem;
                }
                .rates{
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;
                    padding: 0;
                    row-gap: $large;
                    li{
                        font-weight: 500;
                        font-family: $font-title;
                        display: grid;
                        grid-template-columns: 3fr 5fr;
                        border-bottom: 1px solid $color-gray-light-medium;
                        padding-bottom: $large;
                        gap: .5rem;

                        &:last-child {
                            border-bottom: 0;
                        }

                        .prices {
                            font-weight: 400;
                            font-family: $font-base;
                            display: flex;
                            flex-direction: column;
                            gap: .5rem;

                            .price {
                                display: grid;
                                align-items: baseline;
                                gap: 2px;
                                grid-template-columns: auto auto 2fr;

                                &.clinic {

                                    .type {
                                        color: $color-purple;
                                    }
                                }

                                &.capsule {

                                    .type {
                                        color: $color-aqua
                                    }
                                }

                                .type {
                                    margin-right: $large;
                                }

                                .treatment-devises {
                                    font-size: .8rem;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
                .info{

                    &:last-of-type {
                        margin-top: 1rem;
                    }
                    
                    p {
                        font-size: .8rem;
                    }

                }

                @media (max-width:767px) {
                    .rates-icons{
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 4.06rem;
                        row-gap: 1.44rem;
                        .info-block{
                            .icon{
                                display: flex;
                                align-items: center;
                                flex-direction: row;
                                column-gap: 1.25rem;
                            }
                        }
                    }
                    .rates{
                        row-gap: 1.5rem;
                        li{
                            grid-template-columns: 1fr;
                            row-gap: 0.38rem;
                        }
                    }
                    .info{
                        font-size: 0.6875rem;
                    }
                }
            }
            #results{
                padding-bottom: 8.75rem;
                #tabs {
                    width: 100%;

                    .tabs-titles {
                        @media (max-width:1550px) {
                            overflow-x: scroll;

                            /* largeur poignée */
                            &::-webkit-scrollbar {
                                height: 6px;
                                display: none;
                            }
                            
                            /* background */
                            &::-webkit-scrollbar-track {
                                background: transparent;
                            }
                            
                            /* Poignée (thumb) */
                            &::-webkit-scrollbar-thumb {
                                background: $color-black;
                                transition: all .2s linear;
                                border-radius: 8px;
                            }
                            
                            &::-webkit-scrollbar-thumb:hover {
                                background: $color-black;
                                //Firefox + Edge
                                scrollbar-color: transparent $color-black;
                            }
                        }
                    }
                    .tab-title {
                        &.is-active,
                        &:hover {
                            color: $color-purple;
                        }
                    }
                    .img-grid{
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: $large;
                        width: 100%;
                    
                        img{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .three-cols {
                        grid-template-columns: repeat(3, 1fr);

                        @media (max-width:1199px) {
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }
                    
                    .after-before-container{
                        position:relative;
                        height: 24rem;
                        width: calc(100%);
                        overflow: hidden;

                        .imgs-wrapper{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            max-height: 24rem;
                            min-height: 24rem;
                            img{
                                width: 100%;
                                max-height: 24rem;
                                min-height: 24rem;
                                object-fit: cover;
                            }
                        }
                        .treatment-before{
                            z-index: 1;
                            left: -5px;
                            width: 50%;
                        }
                        .treatment-after{
                            z-index: 0;
                            img{
                                width: 100%;
                                z-index: 1;
                            }
                        }
                        input.slider {
                            width: 100%;
                            height: 100%;
                            outline: none;
                            background-color: transparent;
                            position: absolute;
                            margin: 0px;
                            z-index: 3;
                            cursor: pointer;
                            appearance: none;
                            -moz-appearance: none;
                            -webkit-appearance: none;
                            transition: 0.25s all ease-in-out;
                            -moz-transition: 0.25s all ease-in-out;
                            -webkit-transition: 0.25s all ease-in-out;
                            z-index: 2;
                        }
                        input.slider::-moz-range-thumb {
                            width: 6px;
                            height: 600px;
                            background-color: $color-black;
                            cursor: pointer;
                        }
                        input.slider::-webkit-slider-thumb {
                            width: 6px;
                            height: 530px;
                            background-color: $color-black;
                            cursor: pointer;
                            appearance: none;
                            -moz-appearance: none;
                            -webkit-appearance: none;
                        }
                        .slider-button {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            -moz-broder-radius: 50%;
                            -webkit-border-radius: 50%;
                            background-color: $color-black;
                            position: absolute;
                            top: calc(50% - 18px);
                            left: calc(50% - 19px);
                            cursor: pointer;
                            z-index: 1;
                        }
                        .slider-button:before {
                            color: $color-white;
                            position: absolute;
                            font-size: 12px;
                            top: 3px;
                            left: 4px;
                            font-family: $fontawesome-regular;
                            font-weight: 900;
                            content: "\f053";
                        }
                        .slider-button:after {
                            color: $color-white;
                            position: absolute;
                            font-size: 12px;
                            top: 3px;
                            right: 4px;
                            font-family: $fontawesome-regular;
                            font-weight: 900;
                            content: "\f054";
                        }  

                        @media (max-width:1441px) {
                            max-height: 18rem;
                            min-height: 18rem;
                            .imgs-wrapper{
                                max-height: 18rem;
                                min-height: 18rem;
                                img{
                                    max-height: 18rem;
                                    min-height: 18rem;
                                }
                            }
                        }

                        @media (max-width:991px) {
                            max-height: 15rem;
                            min-height: 15rem;
                            .imgs-wrapper{
                                max-height: 15rem;
                                min-height: 15rem;
                                img{
                                    max-height: 15rem;
                                    min-height: 15rem;
                                }
                            }
                        }

                        @media (max-width:767px) {
                            width: 100%;
                            max-height: 9rem;
                            min-height: 9rem;
                            .imgs-wrapper{
                                max-height: 9rem;
                                min-height: 9rem;
                                img{
                                    max-height: 9rem;
                                    min-height: 9rem;
                                }
                            }
                            .treatment-before{
                                width: 50%;
                            }
                            .treatment-after{
                                img{
                                    width: 100%;
                                }
                            }
                            .slider-button:before {
                                top: 9px;
                            }
                            .slider-button:after {
                                top: 9px;
                            }
                        }
                    }
                }
            }
            #testimonials{
                padding-bottom: 3.75rem;

                .testimonial-block {

                    .description {

                        h3 {
                            font-size: $large; //30px
                            line-height: normal;
                            font-family: $font-title;
                            font-weight: 500;
                          
                            @media (max-width:991px) {
                              font-size: $big;
                              line-height: normal;
                            }
                        }

                        h4 {
                            font-size: $big; //24px
                            line-height: 1.6rem;
                            font-family: $font-title;
                            font-weight: 500;
                          
                            @media (max-width:991px) {
                              font-size: $medium;
                              line-height: normal;
                            }
                        }
                    }
                }
                .testimonials-slider{
                    width: 60%;

                    @media (max-width:991px) {
                        width: 100%;
                    }
                    .testimonial-block{
                        .name{
                            font-size: 0.875rem;
                            font-weight: 600;
                            .age{
                                font-weight: 400;
                            }
                        }
                    }
                    .slick-dots {
                        position: relative;
                        padding-top: 2.5rem;
                        margin-bottom: 0;
                        .slick-active{
                            button{
                                background-color: $color-black !important;
                            }
                        }
                        li {
                            border: 1px solid black;
                            border-radius: 100%;
                        }
                    }
                }

                @media (max-width:767px) {

                    .testimonials-slider{
                        .slick-list{
                            width: 100% !important;
                        }
                        .testimonial-block{
                            max-width: 91vw;
                        }
                        .slick-dots {
                            li {
                                button{
                                    width: 0.45rem !important;
                                    height: 0.45rem !important;
                                }
                            }
                        }
                    }
                }
            }
            #taglines{
                padding-top: 8.75rem;
                padding-bottom: 8.75rem;
                border-top: 2px solid $color-black;

                @media (max-width:767px) {
                    padding-top: 4.99rem;
                    border-top: 0;
                }
            }
            #infos{

                .section-title {
                    padding-bottom: 0;
                }

                p.consultation-info {
                    margin-bottom: 2.5rem;
                }

                .infos-content{
                    margin-top: 4.2rem;
                    width: 60%;
                    height: 29rem;
                    padding-right: 1.25rem;
                    overflow-y: scroll;
                    margin-bottom: 10.2rem;

                    @media (max-width:991px) {
                        width: 100%;
                    }
                }
                .infos-content::-webkit-scrollbar{
                  width: 6px;
                  background-color: $color-white;
                }
                .infos-content::-webkit-scrollbar-thumb{
                  border-radius: 10px;
                  width: 6px;
                  -webkit-box-shadow: 0;
                  background-color: $color-black;
                }

                .paragraph{
                    padding-top: 0.75rem;
                    padding-bottom: 2.5rem;
                }

                .icons-grid{
                    display: grid;
                    grid-template-columns: 50% 50%;
                    .icons-grid-child{
                        display: grid;
                        grid-template-columns: 25% 75%;
                        padding-bottom: 2.5rem;
                        p{
                            padding: 0;
                            margin: 0;
                        }
                        img{
                            height: 42px;
                            width: 42px;
                        }
                        .icons-flex-child{
                            display: flex;
                            flex-direction: column;
                            .small{
                                font-weight: 600;
                            }
                        }
                    }
                }

                @media (max-width:767px) {
                    .infos-content{
                        margin-bottom: 5rem;
                        .icons-grid{
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
            #related-treatments,
            #related-products{

                .slick-track {
                    display: flex;
                }
                .img-block.treatment{
                    width: calc(35vw - 3.5rem);
                    height: auto;
                    max-height: 12rem;
                    margin-right: 3.5rem;
                    display: flex !important;

                    @media (max-width:1441px) {
                        width: calc(30vw - 2.5rem);
                        margin-right: 2.5rem;
                    }

                    @media (max-width:1199px) {
                        width: calc(55vw - 3.5rem);
                        margin-right: 3.5rem;
                    }

                    @media (max-width:991px) {
                        width: calc(40vw - 3.5rem);
                    }

                    @media (max-width:767px) {
                        width: calc(40vw - 1rem);
                        margin-right: 1rem;
                        max-height: fit-content;
                    }
                }

                .product-block {
                    width: calc(30vw - 3.5rem);
                    height: auto;
                    display: flex !important;
                    margin-right: 3.5rem;

                    @media (max-width:1441px) {
                        width: calc(30vw - 2.5rem);
                        margin-right: 2.5rem;
                    }

                    @media (max-width:1199px) {
                        width: calc(55vw - 3.5rem);
                        margin-right: 3.5rem;
                    }

                    @media (max-width:767px) {
                        width: calc(80vw - 1rem);
                        margin-right: 1rem;
                    }
                }
            }

            #related-products {
                margin-top: 4rem;
            }
        }
    }
    @media (max-width: 767px) {
        .grid{
            flex-direction: column;
            .side-nav{
                display: none;
            }
            .page-content{
                width: 100%;
                .section-title{
                    padding-bottom: 1.81rem;
                }
            }
        }
    }
}

.tabs {

    .tabs-titles {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 3rem;
        margin-bottom: 3.8rem;

        @media (max-width:767px) {
            white-space: nowrap;
            padding-bottom: 1.2rem;
            overflow-x: scroll;

            /* largeur poignée */
            &::-webkit-scrollbar {
                height: 6px;
                display: none;
            }
            
            /* background */
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            
            /* Poignée (thumb) */
            &::-webkit-scrollbar-thumb {
                background: $color-black;
                transition: all .2s linear;
                border-radius: 8px;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                background: $color-black;
                //Firefox + Edge
                scrollbar-color: transparent $color-black;
            }
        }
    }

    .tab-title {
        cursor: pointer;
        transition: all .2s linear;
        white-space: nowrap;
        
        h2 {
            font-weight: 400;
            text-transform: inherit;
        }
    }

    .tabs-content {
        position: relative;

        .tab {
            background-color: $color-white;
            position: absolute;
            left: 0;
            top: 0;
            display: none;
            visibility: hidden;

            &.is-active {
                display: block;
                visibility: visible;
                position: static;
            }

            ul {
                padding: 0;
                list-style-position: inside;
            }
        }
    }
}

#pillars-section{
    background-color: $color-black;
    padding: 80px 0;
    h2{
        color: $color-white;
    }
    .pillar-intro-description{
        width: 70vw;
        padding: 0 0 60px 0;
        color: $color-white;
    }
    @media (max-width: 991px) {
        .pillar-intro-description{
            width: 100%;
        }
    }
}

#page-about {

    #tabs, #numbers-section, #timeline {
        margin-top: 7.5rem;
    }

    #tabs {
        padding-top: 2rem;
        border-top: 2px solid $color-black;

        .tab-title {
            &.is-active,
            &:hover {
                color: $color-granny-green;
            }
        }
    }

    #numbers-section{
        
        .content{
            display: grid;
            grid-template-columns: 65vw 25vw; 
            justify-content: space-between;
            padding: 0 8.75rem;

            .m-anim{
                position: relative;
                left: -40%;
                top: 0;
                width: 70%;
                height: auto;
            }
            @media (min-width: 2400px){
                .numbers {
                    margin-left: -50%;
                }
            }
            @media (max-width: 2399px){
                .numbers {
                    margin-left: -30%;
                }
            }
            @media (max-width: 1599px){
                grid-template-columns: 60vw 30vw;
            }
            @media (max-width: 1240px){
                .numbers {
                margin-left: -45%;
            }
                grid-template-columns: 56vw 34vw;
            }
            @media (max-width: 991px) {
                .numbers {
                margin-left: 0;
            }
                display: flex;
                flex-direction: column;
                align-items: center;
                
                .m-anim{
                    display: none;
                    margin: 0;
                }
            }
        }
    }

    #timeline {
        margin-bottom: $gigantic;

        .container {
            display: block;
        }

        .slider-content {
            margin-top: 4.5rem;
            padding-bottom: 2rem;
            display: flex;
            position: relative;

            @media (max-width:767px) {
                display: block;
            }
        }

        .timeline-slider {
            margin-right: 100%;

            @media (max-width:767px) {
                margin-right: 0;
            }
        }

        .event {
            display: flex;
            flex-direction: column;
            width: 40vw;
            max-width: 40rem;
            margin-right: 30rem;
            overflow: visible;

            &:last-child {

                .year {
                    &::after {
                        display: none !important;
                        visibility: hidden !important;
                    }
                }
            }

            @media (max-width:1199px) {
                width: 35vw;
                margin-right: 25rem;
            }

            @media (max-width:991px) {
                width: 65vw;
                margin-right: 10rem;
            }

            @media (max-width:767px) {
                margin-right: 2rem;
                max-width: 70vw;
                margin-top: 0 !important;
                flex-direction: column !important;
            }

            &:nth-child(odd) {
                margin-top: 6.5rem;
            }

            &:nth-child(even) {
                flex-direction: column-reverse;
            }

            .year {
                font-family: $font-title;
                font-weight: 900;
                font-size: 9.4rem;
                line-height: normal;
                position: relative;

                @media (max-width:767px) {
                    font-size: 5.5rem;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: calc(100% + 2rem);
                    transform: translateY(-50%);
                    width: 25rem;
                    height: 2px;
                    background-color: $color-black;

                    @media (max-width:1199px) {
                        width: 15rem;
                        left: calc(100% + 5rem);
                    }

                    @media (max-width:991px) {
                        width: 6rem;
                        left: calc(100% + 2rem);
                    }

                    @media (max-width:767px) {
                        display: none;
                        visibility: hidden;
                    }
                }
            }

            h3 {
                margin-bottom: $small;
            }

            p {
                margin: 0;
            }

            &.slick-current {

                .year {
                    color: $color-purple;

                    &::after {
                        background-color: $color-purple;
                    }
                }
            }
        }
    }
}

#page-homepage{

    #treatments-finder {
        background-color: $color-black;
        color: $color-white;
        padding-top: 2.222rem;
        padding-bottom: $gigantic;

        h2 {
            margin-bottom: $huge;
        }

        .filters {
            color: $color-white;
            margin-bottom: $big;
            width: 100%;

            .filter {
                color: inherit;
                opacity: .5;

                &.is-active,
                &:hover {
                    opacity: 1;
                }
            }
        }

        .filters-list {
            margin-bottom: 2.3rem;
        }

        .treatments {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 2.3rem;

            .filter-img {
                width: 100%;
                max-width: 20%;
                height: 300px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @media (max-width:1199px) {
                    max-width: 30%;
                }

                @media (max-width:991px) {
                    display: none;
                    visibility: hidden;
                }
            }
            
            .slick-arrow {
            
                &:before {
                    color: $color-white;
                }
            }

            .slider-content {
                width: 80%;
                display: flex;
                position: relative;
                height: 150px;

                .treatments-slider {
                    margin-right: 100%;
                }

                .slick-list,
                .slick-track {
                    height: 100%;
                }
                
                .slick-track {
                    display: flex !important;
                }

                @media (max-width:1199px) {
                    width: 70%;
                }

                @media (max-width:991px) {
                    width: calc(100% + 1.111rem);
                    display: block;
                    margin-top: 2rem;

                    .slick-arrow {
                        &.slick-prev {
                            right: calc(2.813rem + 1.111rem);
                        }

                        &.slick-next {
                            right: 1.111rem;
                        }
                    }

                    .treatments-slider {
                        margin-right: 0;
                        height: 100%;
                    }
                }
            }
        }

        .treatment-block {
            color: $color-white;
            padding: $regular;
            border: 1px solid $color-white;
            display: inline-block;
            margin-right: 2.222rem;
            width: 100vw;
            max-width: calc(25vw - 2.222rem);
            height: auto;

            &:hover {
                color: $color-black;
                background-color: $color-purple;
                border-color: $color-purple;
            }

            .content {
                height: 100%;
            }

            @media (max-width:1199px) {
                max-width: calc(35vw - 2.222rem);
            }

            @media (max-width:991px) {
                max-width: calc(75vw - 2.222rem);
            }
        }
    }
    .intro{
        padding: 44px 0 80px;

        .btn-primary.popup-btn {
            display: none;
            visibility: hidden;
            width: 100%;

            @media (max-width:767px) {
                display: flex;
                visibility: visible;
            }
        }
        .video-wrapper {
            position: relative;
            width: 100%;
            padding-top: 50%;
            height: 0px;
            overflow: hidden;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            video{
                width: 100%;
                height: auto;
                position: absolute;
                top: 0;
                left: 0;
            }

            .m-anim {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: 100%;
                width: auto;

                svg {
                    height: 100% !important;
                    width: auto !important;
                }
            }
        }
        .pages-shortcuts{
            display: flex;
            justify-content: space-between;
            padding: 0;
            list-style: none;
            .huge{
                font-size: 4rem;
                a{
                    color: $color-black;
                    &:hover{
                        color: $color-purple;
                    }
                }
            }
            .shortcuts-label{
                font-size: 14px;
                text-transform: none;
                &:after{
                    content: "\f061";
                    font-family: $fontawesome-light;
                    padding: 0 0 0 7px;
                    font-size: 10px;
                    
                }
            }
        }
        @media (max-width: 1240px){
            .pages-shortcuts{
                .huge{
                    font-size: $huge;
                }
            }
        }
        @media (max-width: 991px){
            padding: 0 0 40px;
            .pages-shortcuts{
                width: 110vw;
                .slick-prev{
                    right: 28vw;
                    bottom: -55px;
                }
                .slick-next{
                    right: 20vw;
                    bottom: -55px;
                }
            }
        }
        @media (max-width: 767px){
            .pages-shortcuts{
                .huge{
                    font-size: $huge-mobile;;
                }
                width: 110vw;
                .shortcuts-label{
                    font-size: 10px;
                    text-transform: none;
                    &:after{
                        padding: 0 0 0 4px;
                        font-size: 7x;
                    }
                }
            }
        }
    }
    .offers-section{
        margin-top: 7.8rem;

        .section-title{
            padding-bottom: 64px;
        }
        .offers-slider{
            width: calc(100% + 1.667rem);

            .slick-track {
                margin: 0;
            }

            .slick-arrow {
                &.slick-next {
                    right: $large;
                }

                &.slick-prev {
                    right: calc(2.813rem + 1.667rem);
                }
            }

            .offer-block{
                margin-right: $large;

                .img {
                    height: 16.7rem;
                }

                @media (max-width:767px) {
                    width: 100vw;
                    max-width: calc(85vw - 1.667rem);
                }
            }
        }
        @media (max-width: 767px){
            .section-title{
                padding-bottom: 24px;
            }
            .btn-primary{
                margin: 70px 0 0;
            }
        }
    }
    .about-section{
        .container{
            padding-top: 8.75rem;
            p{
                width: 70vw;
            }
            .content{
                display: grid;
                grid-template-columns: 65vw 25vw; 
                justify-content: space-between;
                padding: 8.75rem;
                p{
                    width: 100%;
                }
                .m-anim{
                    position: relative;
                    left: -40%;
                    width: 70%;
                    height: auto;
                }
                .numbers{
                    right: 8rem;
                    position: relative;
                }
            }
        }
        @media (min-width: 2450px) {
            .container{
                .content{
                    .numbers{
                        right: 18rem;
                    }
                }
            }
        }
        @media (max-width: 1599px) {
            .container{
                .content{
                    grid-template-columns: 60vw 30vw;
                    .numbers{
                        right: 12rem;
                    }
                }
            }
        }
        @media (max-width: 1240px) {
            .container{
                .content{
                    grid-template-columns: 56vw 34vw; 
                }
            }
        }
        @media (max-width: 991px) {
            .container{
                padding-top: 5rem;
                h2, p:first-of-type{
                    width: 100%;
                }
                .content{
                    padding-top: 5rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .m-anim{
                        display: none;
                        margin: 0;
                    }
                    .numbers{
                        right: 0;
                    }
                }
            }
        }
    }

    #clinics-finder{
        .section-title{
            margin-top: 140px;
            margin-bottom: 26px;
            padding-bottom: 64px;
            flex-wrap: wrap;
            h2{
                width: 100%;
                margin-bottom: 2rem;
            }
        }

        .filters {
            @media (max-width:991px) {
                display: none;
            }
        }

        .filters-mobile {
            border-color: $color-black;

            ul {
                background-color: white;
                border-color: black;

                li {
                    &.is-active,
                    &:hover {
                        background-color: $color-black;
                        color: $color-white !important;
                    }
                }
            }

            &.is-open {

                ul {
                    visibility: visible;
                    display: initial !important;
                }
            }
        }

        .clinics-slider {
            width: calc(100% + 2.25rem);

            .slick-track {
                display: flex;
                margin: 0;
            }

            .slick-arrow {
                &.slick-next {
                    right: 2.25rem;
                }

                &.slick-prev {
                    right: calc(2.813rem + 2.25rem);
                }
            }
        }

        .clinic-block {
            height: auto;
            margin-left: 1px;
            margin-right: 2.25rem;
        }
        @media (max-width: 1240px){
            .section-title{
                margin-bottom: 0;
            }
        }
        @media (max-width: 767px){
            .section-title{
                padding-bottom: 24px;
            }

            .clinic-block {
                width: 100vw;
                max-width: calc(85vw - 2.25rem);
            }
        }
    }
}

#page-landingPage,
#page-capsules{

    .clinics-grid {
        margin-bottom: 7.5rem;
    }

    #cta-title{
        display: flex;
        justify-content: flex-start;
        background-color: $color-black;

        a {
            width: 100%;
            padding: 8.75rem 0;
            display: block;
        }
        h3, h2{
            color: $color-white;
            width: 100%;
            max-width: 60%;
        }
        
        .container{
            display: flex;
            flex-direction: column;
            row-gap: 2.5rem;
            &:after{
                position: absolute;
                left: 55vw;
                color: $color-white;
                content: "\f061";
                font-family: $fontawesome-light;
                font-size: 4.375rem;
                bottom: 3.313rem;
            }
        }
        @media (max-width: 991px) {
            
            a {
                padding: 2.5rem 0;
            }
            .big-title{
                font-size: 6rem;
                line-height: 5.5rem;
            }

            h2, h3 {
                max-width: 70%;
            }
            .container{
                &:after{
                    bottom: 0rem;
                    left: 72vw;
                }
            }
        }
    }
    #related-offers{
        margin-top: 3.75rem;
        .section-title{
            padding-bottom: 64px;

            @media (max-width:767px) {
                padding-bottom: 24px;
            }
        }
        .offers-slider{
            width: calc(100% + 1.667rem);

            .slick-track {
                margin: 0;
            }

            .slick-arrow {
                &.slick-next {
                    right: $large;
                }

                &.slick-prev {
                    right: calc(2.813rem + 1.667rem);
                }
            }

            .offer-block{
                margin-right: $large;

                .img {
                    height: 16.7rem;
                }

                @media (max-width:767px) {
                    width: 100vw;
                    max-width: calc(85vw - 1.667rem);
                }
            }
        }
    }
}

#page-offers{
    .offers-grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 32px;
        row-gap: 40px;
    }

    @media (max-width: 1240px) {
        .offers-grid{
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 767px) {
        .offers-grid{
            grid-template-columns: 1fr;
        }
    }
}

#single-offer{
    .related-treatments-slider,
    .related-products-slider {
        padding-top: 4rem;
        .img-block.treatment{
            max-width: 25.125rem;
            min-height: 10rem;
            max-height: 12rem;
            margin-right: 3.75rem;
            display: flex !important;
        }
    }
    .cta-section{
        padding: 8.75rem 0 0;
    }
    @media (max-width: 991px) {
        .cta-section{
            padding: 5rem 0 0;
        }
    }
}

#single-job{
    ul{
        padding: 0;
        margin: 0;
        margin-left: 20px;
    }
    .languages{
        margin-bottom: 18px;
    }
    .descr{
        padding: 0 0 5rem;
    }
    #apply-section{
        .section-title{
            padding-bottom: 4rem;
        }
        .form .gform_fields{
            column-gap: 2.5rem;
            row-gap: 2rem;
        }
        .gform_button_select_files{
            flex-direction: column-reverse;
        }
        .validation_message{
            position: relative;
            top: -25px;
            margin: 35px 0 20px;
        }

        .gform_footer {
            input {
                @media(max-width:576px) {
                    width: 100%;
                }
            }
        }
    }

    #taglines {
        margin-top: 8.75rem;
    }
}

#single-team{
    .bio{
        width: 45%;
        padding-bottom: 8.75rem;
        .small{
            text-transform: inherit;
        }
    }
    @media (max-width: 991px) {
        .bio {
            width: 90vw;
            padding-bottom: 5rem;
        }
    }
}

#single-clinic{
    #img-grid {
        margin-top: -5rem;

        @media (max-width:991px) {
            margin-top: 5rem;
            margin-bottom: 5rem;
        }

        .img-container {
            &:nth-child(1),
            &:nth-child(3) {
                margin-top: 0;
            }
            &:nth-child(2) {
                margin-top: 10%;
            }

            &:nth-child(4) {
                margin-top: 20%;
            }

            @media (max-width:991px) {
                width: calc(50% - .6rem);
                padding-top: 0;
          
                &:nth-child(odd) {
                  margin-top: 15%;
                }
          
                &:nth-child(even) {
                  margin-top: 0;
                }
            }
        }
    }
    .clinic-infos{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 4.69rem;
        padding-bottom: 8.75rem;
        .contact{
            row-gap: 0.37rem;
        }
        .clinic-infos-column{
            display: flex;
            flex-direction: column;
            a{
                margin: 0;
                color: $color-black;
                img{
                    padding-bottom: 0.87rem;
                    margin-left: -10px;
                }
                &:hover{
                    color: $color-gray-light;
                }
            }
            p{
                margin: 0;
            }
            .chatWA{
                padding-top: 0.31rem;
                &:before{
                    font-family: $fontawesome-brands;
                    content: "\f232";
                    font-weight: 100;
                    padding-right: 0.37rem;
                }
            }
            .bold{
                padding-bottom: 0.31rem;
            }
            .specials-wrapper{
                display: flex;
                justify-content: space-between;
                z-index: 1;
                background-color: $color-white;
                padding-top: 1.25rem;
                overflow: hidden;
                cursor: pointer;
                border-bottom: 1px solid $color-black;
                &:after{
                    content: "\f063";
                    font-weight: 100;
                    font-family: $fontawesome-light;
                    transition: transform 0.3s ease;
                }
                &.active{
                    &:after{
                        transform: rotate(180deg);
                    }
                }
            }
            .special{
                display: flex;
                flex-direction: column;
                row-gap: 0.6rem;
                padding-top: 20px;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s ease;
                ul{
                    margin-top: 6px;
                    margin-bottom: 12px;
                    padding-left: 20px;
                    list-style-position: inherit;
                }
            }
        }

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;
            row-gap: 2.5rem;
            padding-bottom: 3.75rem;
        }
    }
    #related-offers{
        padding-bottom: 8.75rem;
        .offers-slider{
            padding-top: 4rem;
            width: calc(100% + 1.667rem);

            .slick-track {
                margin: 0;
            }
            .slick-arrow {
                &.slick-next {
                    right: $large;
                }
    
                &.slick-prev {
                    right: calc(2.813rem + 1.667rem);
                }
            }
    
            .offer-block{
                margin-right: $large;
    
                .img {
                    height: 16.7rem;
                }
    
                @media (max-width:767px) {
                    width: 100vw;
                    max-width: calc(85vw - 1.667rem);
                }
            }
        }
    }
    #related-team {
        margin-bottom: 7.8rem;

        h2.section-title {
            margin-bottom: 64px;

            @media (max-width:767px) {
                margin-bottom: 24px;
            }
        }

        .slider-container {
            display: flex;
            width: 100%;
            position: relative;

            .team-slider {
                margin-right: 100%;
            }

            @media (max-width:767px) {
                display: block;

                .team-slider {
                    margin-right: 0;
                }
            }

            @media (max-width:576px) {
                width: calc(100% + 1.111rem);

                .slick-arrow {
                    &.slick-next {
                        right: 1.111rem;
                    }

                    &.slick-prev {
                        right: calc(2.813rem + 1.111rem);
                    }
                }
            }
        }

        .member-block {
            max-width: 18vw;
            padding-top: 18vw;
            margin-right: 2rem;

            @media (max-width:1199px) {
                max-width: 26vw;
                padding-top: 26vw;
            }

            @media (max-width:991px) {
                margin-right: 1.5rem;
            }

            @media (max-width:767px) {
                max-width: 40vw;
                padding-top: 40vw;
            }

            @media (max-width:576px) {
                max-width: 75vw;
                padding-top: 75vw;
                margin-right: $small;
            }
        }
    }
}

#page-blog {

    .filters-mobile {
        border-color: $color-black;

        ul {
            background-color: white;
            border-color: black;

            li {
                &.is-active,
                &:hover {
                    background-color: $color-black;
                    color: $color-white !important;
                }
            }
        }
    }

    .news-grid {
        margin-top: 4.5rem;
        margin-bottom: 7.8rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2.2rem;
        row-gap: 3.5rem;
    }
}

#single-news{
    display: flex;
    flex-direction: column;
    justify-content: center;
    .news-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 8.75rem;
        row-gap: 2.5rem;
        width: 70%;
        margin-left: auto;

        @media (max-width:991px) {
            width: 100%;
        }
        .paragraph{
            display: flex;
            flex-direction: column;
            row-gap: 2.5rem;
            width: 100%;
            img{
                width: 100%;
            }
            p{
                margin: 0;
                padding: 0;
            }

            a {
                color: $color-purple;
            }
        }
        .news-cta{
            display: flex;
            gap: 1.5rem;
            border: 1px solid $color-black;
            min-height: 8rem;
            width: 100%;
            background-color: $color-black;
            .img-container{
                width: 11rem;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .content{
                width: 100%;
                display: inline-flex;
                gap: 1.25rem;
                justify-content: space-between;
                align-items: center;
                padding: 2.2rem;
                color: $color-white;
            }

            .btn-primary {
                white-space: nowrap;
            }
            &:nth-child(odd){
                flex-direction: row-reverse;
            }
        }
        .blocks-grid{
            display: flex;
            gap: 31px;
            border-top: 2px solid $color-black;
            padding-top: 2.2rem;
            margin-top: 6.5rem;
            width: 100%;

            a{
                display: grid !important;
                grid-template-columns: 45% auto;
                color: $color-black !important;
                transition: $transition-base;
                min-height: 13.25rem;
                max-height: 16.5rem;
                &:hover {
                    color: $color-white !important;
                    .content {
                        border-color: $color-black;
                        border-left: none;
                        background-color: $color-black;
                    }
                }
                .img-container {
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .content {
                    padding: $small;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    background-color: $color-green;
                    border: 1px solid transparent;
                    border-left: none;
                    white-space: pre-wrap;
                    text-align: left;
                    transition: $transition-base;
                }
            }
        }
        .embed{
            max-width: 100%;
            width: 100%;
            iframe{
                max-width: 100% !important;
            }
        }
    }
    #related-news{
        .news-grid{
            display: flex;
            gap: 1.88rem;
            padding: 4rem 0 8.75rem;
            .slick-track {
                width: 100% !important;
                display: flex;
                gap: $large;
                .news,
                .treatment{
                    display: flex !important;
                    max-width: 50% !important;
                    .img{
                        height: 18rem;
                    }
                    .btn{
                        margin-top: 1rem !important;
                    }
                }
            }
        }
    }
    #newsletter-section{
        margin-bottom: 0;
    }
    @media (max-width: 991px) {
    .news-content{
        row-gap: 1.62rem;
        padding-bottom: 5.87rem;
        .paragraph{
            display: flex;
            flex-direction: column;
            row-gap: 1.62rem;
            width: 100%;
            img{
                width: 100%;
            }
            p{
                margin: 0;
                padding: 0;
            }
        }
        .news-cta{
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            margin: 1rem 0;
            .img-container{
                height: 6rem;
                width: 100%;
                display: flex;
                justify-content: center;
                img{
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1.13rem;
                padding: 0.69rem 0.69rem 1.5rem;
            }
            &:nth-child(odd){
                flex-direction: column;
            }
        }
        .blocks-grid{
            padding-top: 3.75rem;
            flex-direction: column;
            gap: 1.5rem;

            a {
                max-height: fit-content;
            }
        }
    }
        #related-news{
            .news-grid{
                display: flex;
                gap: 1.88rem;
                padding: 1.5rem 0 5.75rem;
                
                .slick-track {
                    width: 107% !important;
                    display: flex;
                    gap: 1rem;
                    .news,
                    .treatment{
                        flex-direction: column;
                        .img{
                            max-width: 100%;
                            max-height: 10rem;
                            width: 100%;
                            height: 10rem;
                        }
                        p{
                            display: none;
                        }
                    }
                }
                .slick-arrow{
                    bottom: 2.875rem;
                }
            }
        }
    }
}

#page-shop,
#page-product_cat {

    .filters {
        flex-wrap: wrap;
        row-gap: $big;

        @media (max-width:991px) {
            row-gap: 0;
        }

        .filters-list.children {
            width: 100%;

            li {
                display: none;

                &.is-visible {
                    display: initial;
                }
            }
        }
    }

    .filters-mobile {
        border-color: $color-black;

        ul {
            background-color: white;
            border-color: black;

            li {
                &.is-active,
                &:hover {
                    background-color: $color-black;
                    color: $color-white !important;
                }
            }
        }

        &.is-active {

            .title {
                color: $color-white;
                background-color: $color-black;
            }
        }

        &#filters-mobile-children {
            display: none !important;

            &.is-visible {
                display: block !important;
            }

            .filters-list.children {

                li {
                    &.is-visible {
                        display: block;
                    }
                }
            }
        }
    }

    .products-grid {
        margin-top: $gigantic;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: $large;
        row-gap: $gigantic;

        .brand-tag{
            text-transform: uppercase;
            margin-bottom: 0;
        }

        #empty-msg {
            grid-column: span 3;
        }

        @media (min-width:1920px) {
            grid-template-columns: repeat(4, 1fr);

            #empty-msg {
                grid-column: span 4;
            }
        }

        @media (max-width:767px) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: $large;

            #empty-msg {
                grid-column: span 2;
            }
        }

        @media (max-width:576px) {
            grid-template-columns: 1fr;

            #empty-msg {
                grid-column: auto;
            }
        }
    }

    .category-infos {
        width: 100%;
        max-width: 50%;
        margin-top: 6rem;

        @media (max-width:767px) {
            max-width: 100%;
        }

        h3 {
            margin-bottom: .8rem;
        }
    }
}

#single-product {

    margin-top: 161px;
    padding-top: 2.556rem;

    .product {

        display: grid;
        grid-template-columns:45% 55%;
        padding-top: 0 !important;
        margin-top: 1.75rem;
        color: $color-white;

        .accordion-content{
            ul{
                margin-left: 20px;
                padding-left: 0;
            }
        }

        .hide-price{
            display: none;
        }

        .onsale {
            display: none !important;
        }

        .product-header-slider {

            width: 100%;
            height: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            .slick-list {    
                height: 100%;
                padding: 0;      
                .slick-track {
                    height: 100%;
                }
            }

            .slick-dots {
                left: 48%;
                bottom: -24px;
                li {
                    border: 1px solid $color-black;
                    border-radius: 100%;

                    &.slick-active {

                        button {
                            background-color: $color-black !important
                        }
                    }
                }
            }
        }

        .summary {
            color: $color-white;
            background-color: $color-black;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 1.5rem 3.25rem 2.5rem 2.25rem;
            justify-content: space-between;
            .product-infos {
                .small{
                    font-weight: 600;
                }
                .discount-display{
                    margin-top: 1.75rem;
                }
                .product_title{
                    padding-top: 0.31rem;
                    padding-bottom: 1rem;
                }
                .product-description {

                    ul {
                        padding: 0;
                        margin-left: $medium;
                    }
                }
            }
            .infos{
                padding-top: 2.4rem;
                width: 50%;
                display: flex;
                justify-content: space-between;
                .small{
                    font-weight: 600;
                }
                p{
                    margin: 10px 0 0;
                }
            }
            .price-container{
                padding-top: 2rem;
                display: flex;
                row-gap: 0.88rem;
                flex-direction: column;
                align-items: flex-end;
            }
            .price {
                &.is-onsale {

                    del {
                        font-size: 14px;
                        line-height: normal;
                    }
              
                    ins {
                    text-decoration: none;
                    }
                }
            }
            .product_meta {
                display: none;
                span {
                    span {
                        display: inline;
                    }
                }
            }
            form.cart {
                color: $color-white !important;

                input {
                    color: inherit;
                }
            }
        }

        .woocommerce-tabs{
            margin-top: 10rem;
            .accordion-title{
                color: $color-black;
                display: flex;
                justify-content: space-between;
                z-index: 1;
                background-color: $color-white;
                padding-top: 1.25rem;
                padding-bottom: 1rem;
                overflow: hidden;
                cursor: pointer;
                border-bottom: 0.125rem solid $color-black;
                &:after{
                    content: "\f063";
                    font-weight: 100;
                    font-family: $fontawesome-light;
                    transition: transform 0.3s ease;
                }
                &.active{
                    &:after{
                        transform: rotate(180deg);
                    }
                }
            }
            .accordion-content{
                color: $color-black;
                display: flex;
                flex-direction: column;
                row-gap: 0.6rem;
                padding-top: 20px;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s ease;
                ul{
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
                p{
                    margin: 0;
                }
            }
        }

        input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
      
        input[type='number'] {
            -moz-appearance: textfield;
        }

        .quantity-controls{
            display: flex;
            border: 1px solid $color-white;
            border-radius: 0.5rem;
            width: fit-content;
            align-items: center;
            margin-right: 2.06rem;
        }

        .minus-btn, .plus-btn{
            background-color: $color-black;
            color: $color-white;
            padding: .1rem .3rem;
            font-weight: 400;
            font-size: 15px;
            font-family: 'Font Awesome 6 Pro';
        }

        .quantity{
            margin: 0;
        }

        .qty{
            width: 1.631em;
            padding: 0;
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.5rem;
        }

        .update-price{
            font-weight: 600;
        }

        .cart{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        .btn-primary {
            padding: .383rem .667rem .333rem ;
            border: 1px solid $color-black;
            border-radius: .56rem !important;
            display: flex;
            align-items: center;
            justify-content: center;
            height: fit-content;
            width: fit-content;
            text-align: center;
            font-size: $regular;
            line-height: normal;
            font-family: $font-title;
            font-weight: 500;
            background-color: $color-white;
            color: $color-black;
            transition: $transition-base;
          
            &:hover,
            &.reverse {
              color: $color-white !important;
              background-color: $color-black !important;
              border-color: $color-white;
            }

            &.reverse {
                &:hover {
                    color: $color-black !important;
                    background-color: $color-white !important;
                    border-color: $color-black;
                }
            }
        }
    }

    #video-section {
        margin-top: 8.75rem;
        .container{
            display: flex;
            justify-content: center;
        }

        .video-container {
            position: relative;
            width: 100%;
            max-width: 66%;
            margin: 0 auto;
            video{
                width: 100%;
            }

            &.is-playing {
                
                .btn-play {
                    
                    &.is-hidden {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }

            .btn-play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                color: $color-black;
                padding: 0;
                background-color: transparent;
                border: none;
                font-size: 5rem;
            }
        }
    }

    #related-products {
        margin: 7.8rem 0;
        .section-title{
            padding-bottom: 3.81rem;
        }

        .related-products-slider {
            width: calc(100% + 1.75rem);
            .slick-track {
                margin: 0;
                .product-block{
                    margin-right: 1.75rem;
                }
            }

            .slick-arrow {
                &.slick-prev {
                    right: calc(2.813rem + 1.75rem);
                }

                &.slick-next {
                    right: 1.75rem;
                }
            }
        }
    }

    @media (max-width: 991px) {
        margin-top: 150px;
        #breadcrumb{
            padding: 0 1.12rem;
        }
        .container{
            padding: 0;
            max-width: 100%;
        }
        .product{
            display: flex;
            flex-direction: column;
            .product-header-slider{
                margin: 0 !important;
                .slick-dots{
                    bottom: 14px;
                    li{
                        button{
                            width: 0.45rem !important;
                            height: 0.45rem !important;
                        }
                    }
                }
            }
            .woocommerce-tabs{
                margin-top: 3.75rem;
                padding: 0 1.12rem;
            }
        }
        #video-section{
            padding: 0 1.12rem;
            margin-top: 3.75rem;
            margin-bottom: 5rem;
            .video-container{
                max-width: 100%;
            }
        }
        .cta-section{
            padding: 0 1.12rem;
        }
        #related-products{
            max-width: 90vw;
            margin: 7.8rem auto;
            .section-title{
                padding-bottom: 1.5rem;
            }
            .slick-track {
                .product-block{
                    margin-right: 0.94rem !important;
                }
            }

            .related-products-slider {
                width: calc(100% + .94rem);

                .slick-arrow {
                    &.slick-prev {
                        right: calc(2.813rem + 0.94rem);
                    }
    
                    &.slick-next {
                        right: 0.94rem;
                    }
                }
            }
        }
    }
    @media (max-width:576px) {
        margin-top: 66px;
        .product {
            .summary{
                margin: 0;
                padding: 1rem 1.12rem 2.5rem;
                .product-infos{
                    .infos{
                        width: 100%;
                    }
                }             
                .cart{
                    flex-direction: column;
                }
                .woocommerce-simple-price{
                    margin-bottom: -2.55rem;
                }
                .quantity-controls {
                    width: 40%;
                    justify-content: space-around;
                    margin-bottom: 1rem;
                    margin-right: 0;
                }
                .single_add_to_cart_button{
                    width: 100%;
                }
                .minus-btn{
                    padding: .5rem .5rem .5rem 2rem;
                }
                .plus-btn{
                    padding: .5rem 2rem .5rem .5rem;
                }
            }
        }

        .related-products-slider {

            .product-block {
                width: 80vw;
            }
        }
    }

}

.pillars{
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width:1399px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media (max-width:991px) {
        grid-template-columns: 1fr;
    }

    .pillar-block{
        .pillar-icon{
            height: 35px;
            img{
                width: 100%;
            }
        }
        .pillar-content{

            .pillar-description {
                &.full {
                    max-height: fit-content;
                }
            }

            .btn-icon {
                width: 90%;
                margin-left: 50px;
                font-weight: 600;
                color: white;
                background-color: black;
                border-color: white;
                margin-top: $medium;

                &:hover {
                    color: black;
                    background-color: white;
                }
            }

            .read-more{
                width: 90%;
                margin: 0 0 0 50px;
                justify-content: space-between;
                padding: 20px 0 6px !important;
                color: $color-white;
                font-weight: 600;
                background-color: $color-black;
                border-radius: 0;
                border-bottom: 1px solid #FFF;
                transition: all 0.5s ease;
                span{
                    transition: all 0.3s ease;
                }
                &::after{
                        content: "\f063";
                        font-family: $fontawesome-light;
                        transition: transform .3s ease;
                  }
                &.inverse:after{
                    transition: transform .3s ease;
                    transform: rotate(180deg);
                }
                &.inverse{
                  border-bottom: 1px solid rgba(255, 255, 255, .0);
                  span{
                    opacity: 0;
                    transition: all 0.3s ease;
                  }
                }
                &:hover{
                    color: $color-white;
                    background-color: $color-black;
                }
            }

            .btn-icon {
                display: flex;
                width: fit-content !important;
                gap: .5rem;
            }
        }

        &.no-icon {
            .pillar-description {
                padding-left: 0 !important;
            }
        
            .read-more {
                width: 100%;
                margin-left: 0;
            }

            .btn-icon {
                margin-left: 0;
            }
        }
    }
}

#page-default{
    .container{
        width: 50%;
        h2{
            text-transform: inherit;
            font-size: 2.875rem;
            font-weight: 600;
            line-height: 3.19375rem;
        }
        h3{
            text-transform: inherit;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 2.25rem;
        }
        h5{
            text-transform: inherit;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 2.25rem;
        }
    }
    @media (max-width: 991px){
        .container{
            width: 100%;
        }
        h2{
            font-size: 2.556rem;
        }
    }
    @media (max-width: 767px){
        h2{
            font-size: 1.625rem;
        }
    }
}

#page-header {
    &.search-header {
        width: 100%;
        max-width: 83%;
        margin: 0 auto;
        margin-top: 179px;
        margin-bottom: $gigantic;

        @media (max-width:1199px) {
            margin-top: 175px;
        }
      
        @media (max-width:991px) {
            margin-top: 168px;
            max-width: 100%;
        }
      
        @media (max-width:767px) {
            margin-top: 72px;
        }

        .search-content {
            margin-top: $gigantic;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: $small;
            padding-bottom: $small;
            border-bottom: 1px solid black;

            i {
                font-size: $huge;
                color: #BABABA;
            }
        }

        h2 {
            font-size: $small;
            text-transform: none;
        }

        h1 {
            font-size: $large;
            font-weight: 500;

            span {
                color: $color-purple;
            }
        }
    }
}

#page-search {
    margin-bottom: 8.9rem;
    
    .results {
        width: 100%;
        max-width: 83%;
        margin: 0 auto;
    
        @media (max-width:991px) {
            max-width: 100%;
        }
    }
}

.search-block {
    display: flex;
    gap: $big;
    text-align: left;
    color: $color-black;
    border-top: 1px solid #BABABA;
    padding: $large 0;

    &:first-child {
        border-top: none;
    }

    .img {
        position: relative;
        max-width: 25%;
        min-width: 25%;
        max-height: 12rem;

        @media (max-width:991px) {
            max-width: 35%;
            min-width: 35%;
            max-height: 16rem;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $color-green;
            z-index: 1;
            transform: scaleY(0);
            transition: transform .4s ease-in-out;
            transform-origin: bottom;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h3 {
        margin-top: $small;
    }

    p {
        margin-top: $small;
        max-width: 50rem;
    }

    &.clinic {

        .img {
            background-color: black;
            text-align: center;
            padding: 2rem;

            img {
                width: auto;
                object-fit: contain;
            }
        }

        address {
            margin-top: $small;
        }
    }

    &.treatment {

        .img {
            &::before {
                background-color: $color-purple;
            }
        }
    }

    &.offer {

        .img {
            &::before {
                background-color: $color-aqua;
            }
        }
    }

    &.press,
    &.news {

        >div:not(.img) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            height: auto;

            .btn {
                margin-top: auto;
            }
        }
    }

    &:hover {

        .img {
            &::before {
               transform: scaleY(1);
               transform-origin: top;
            }
        }
    }
}