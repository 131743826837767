//
// WooCommerce Styles
// --------------------------------------------------

.woocommerce {
    .single_add_to_cart_button, #place_order, .checkout-button {
        background-color: $color-link !important;
        border-radius: 0 !important;
        &:hover {
            background-color: $color-link-hover !important;
        }
        &:focus {
            outline: none;
        }
    }
    .related.products {
        margin-top: $margin-base;
    }
    .onsale {
        background-color: $color-primary !important;
    }
    .woocommerce-info {
        border-top-color: $color-primary;
        &:before {
            color: $color-primary;
        }
    }
    .widget_price_filter {
        .ui-slider {
            .ui-slider-range {
                background-color: $color-primary;
            }
            .ui-slider-handle {
                background-color: $color-primary;
            }
        }
        .price_slider_wrapper {
            .ui-widget-content {
                background-color: #ebe9eb;
            }
        }
    }
    .product {
        .summary {
            .cart {
                .quantity {
                    .input-text {
                        padding: 5px;
                    }
                }
            }
        }
    }
    .cart-collaterals {
        margin-top: $margin-base;
    }
    .woocommerce-pagination {
    	.page-numbers {
    		border: 0 !important;
    		li {
    			display: inline-block !important;
    			border-right: 0 !important;
    			float: none !important;
    			a, span {
    				color: white !important;
    				text-decoration: none !important;
    				background-color: $color-gray-dark !important;
    				cursor: pointer !important;
    				padding: 5px 13px !important;
    				display: block !important;
    				font-size: 14px !important;
    				line-height: inherit !important;
    				&:hover {
    					background-color: $color-primary !important;
    					color: #ffffff !important;
    				}
    				&.current {
    					background-color: $color-primary !important;
    					color: #ffffff !important;
    				}
    			}
    		}
    	}
    }
}

.cart-empty{
    margin: 1.2em 0 2em;
    line-height: 2em;
    background-color: #FFF;
    border-right: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
    border-left: 1px solid $color-black;
    &:before{
        top: 0.9em;
    }
}

.woocommerce-notices-wrapper{
    .woocommerce-error{
        margin: 1em 0 2em;
        background-color: #FFF;
        border-top-color: $color-red;
        border-right: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
        border-left: 1px solid $color-black;
        &:before{
            color: $color-red;
        }
    }
    .woocommerce-message{
        margin: 1.2em 0 2em;
        line-height: 2em;
        background-color: #FFF;
        border-top-color: $color-green;    
        border-right: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
        border-left: 1px solid $color-black;
        &:before{
            top: 0.9em;
            color: $color-green;
        }
        a{
            line-height: 1em;
        }
    }
    @media (max-width: 991px ){
        padding: 0 1.112rem;
    }
    @media (max-width: 576px ){
        .woocommerce-message{
            line-height: 1.4rem;
            display: flex;
            flex-direction: column-reverse;
            flex-wrap: wrap;
            &:before{
                top: 1rem;
            }
            a{
                line-height: 1em;
                width: 100%;
                margin: 1rem 0 0;
            }
        }
    }
}

#customer_login{
    label{
        position: relative;
    }
    .woocommerce-form-login, .woocommerce-form-register{
        border: solid 1px $color-black;
        border-radius: 0;
        padding: 2rem 1.333rem;
        input:-webkit-autofill { 
            -webkit-background-clip: text;
        }
        .woocommerce-form-login__rememberme{
            margin-top: 1.9rem;
        }
        .show-password-input{
            right: 0;
            top: 0;
        }

        button{
            margin-top: 1.333rem;
            padding: .383rem .667rem .333rem ;
            border: 1px solid $color-black;
            border-radius: .56rem; 
            display: flex;
            align-items: center;
            justify-content: center;
            height: fit-content;
            width: fit-content;
            text-align: center;
            font-size: $regular;
            line-height: normal;
            font-family: $font-title;
            font-weight: 500;
            background-color: $color-white;
            color: $color-black;
            transition: $transition-base;

            &:hover,
            &.reverse {
                color: $color-white !important;
                background-color: $color-black;
                border-color: $color-white;
            }
        }

        a {
            color: $color-purple;
        }
    }
    
}

.woocommerce-MyAccount-navigation{
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            font-size: 1.4rem;
            line-height: 1.5rem !important;
            margin-bottom: 1rem;
            font-family: "Antarctica Condensed",sans-serif;
            font-weight: 500;
            a{
                color: #000;
                opacity: .3;
            }
            &:hover{
                a{
                    opacity: 1;
                }
            }
        }
        .is-active{
            a{
                opacity: 1;
            }
        }
    }
    @media (max-width: 991px) {
        margin-top: -4rem;
        ul{
            li{
                line-height: 2.1rem !important;
            }
        }
    }
}

.woocommerce-MyAccount-content{
    a{
        color: $color-purple;
    }
    .woocommerce-info{
        margin: 1.2em 0 2em;
        line-height: 2em;
        background-color: #FFF;
        border-top-color: $color-purple;    
        border-right: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
        border-left: 1px solid $color-black;
        &:before{
            top: 0.9em;
            color: $color-purple;
        }
        a{
            line-height: 1em;
        }
    }
    .woocommerce-orders-table__cell-order-actions{
        a{
            width: 100%;
        }
    }
    .woocommerce-orders-table{
        border: solid 1px $color-black !important;
        border-radius: 0 !important;
        thead{
            th{
                border-bottom: solid 2px $color-black;
            }
        }
    }
    .woocommerce-Address{
        border: solid 1px $color-black;
        padding: 1rem;
        header{
            h3{
                font-size: 1.556rem;
            }
            a{
                margin-top: 0.7rem;
            }
        }
    }
    .edit-account, .woocommerce-address-fields{
        label{
            position: relative;
            margin-bottom: -0.7rem;
            margin-top: 0.7rem;
        }
        fieldset{
            padding: 0;
            margin-top: 2rem;
            legend{
                padding: 1rem 0;
            }
        }
        em{
            font-size: 16px;
        }
        button{
            margin-top: 1.333rem;
            padding: .383rem .667rem .333rem ;
            border: 1px solid $color-black;
            border-radius: .56rem; 
            display: flex;
            align-items: center;
            justify-content: center;
            height: fit-content;
            width: fit-content;
            text-align: center;
            font-size: $regular;
            line-height: normal;
            font-family: $font-title;
            font-weight: 500;
            background-color: $color-white;
            color: $color-black;
            transition: $transition-base;

            &:hover,
            &.reverse {
                color: $color-white !important;
                background-color: $color-black;
                border-color: $color-white;
            }
        }
        .woocommerce-password-strength.bad {
            background-color: $color-red;
        }
        .woocommerce-password-strength.strong{
            background-color: $color-green;
        }
    }
    @media (max-width: 991px) {
        padding-top: 1.4rem;
        .woocommerce-orders-table{
            .woocommerce-orders-table__cell-order-actions{
                &:before{
                    display: none;
                    a{
                        width: 100%;
                    }
                }
            }
        }
        .woocommerce-Addresses{
            header{
                display: flex;
                flex-direction: column;
                padding-bottom: 1rem;
            }
            .col-1{
                margin-bottom: 1.112rem;
            }
        }
    }
}

.woocommerce-order{
    margin-top: -3.5rem;
    a{
        color: $color-purple;
    }
    .woocommerce-order-overview{
        margin: 1rem 0 3rem;
        padding: 0;
        li{
            border-right: 0 !important;
        }
    }
    .woocommerce-order-details{
        h2{
            margin-bottom: 1rem;
            margin-top: 2rem;
        }
        table{
            border: solid 1px $color-black;
            border-radius: 0;
            thead{
                th{
                    border-bottom: solid 2px $color-black;
                }
            }
        }
    }
    .woocommerce-customer-details{
        margin-top: 3rem;
        h2{
            font-size: 1.8rem;
            padding-bottom: 1rem;
        }
        address{
            display: flex;
            flex-direction: column;
            row-gap: 0.8rem;
            border: solid 1px $color-black;
            border-radius: 0;
            padding: 1.5rem 1rem;
            border-bottom-width: 1px;
            border-right-width: 1px;
            p{
                display: flex;
                align-items: center;
                margin: 0;
            }
        }
    }
    @media (max-width: 991px) {
        .woocommerce-order-overview{
            display: flex;
            flex-direction: column;
            row-gap: .8rem;
        }
        .woocommerce-customer-details{
            section{
                .col-1{
                    padding-bottom: 2rem;
                }
            }
        }
    }
}

.woocommerce-ResetPassword{
    label{
        position: relative;
    }
    button.button{
        margin-top: 1.333rem;
        padding: .383rem .667rem .333rem ;
        border: 1px solid $color-black;
        border-radius: .56rem; 
        display: flex;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: fit-content;
        text-align: center;
        font-size: $regular;
        line-height: normal;
        font-family: $font-title;
        font-weight: 500;
        background-color: $color-white;
        color: $color-black;
        transition: $transition-base;

        &:hover,
        &.reverse {
            color: $color-white !important;
            background-color: $color-black;
            border-color: $color-white;
        }
    }
}

.variations_form.cart{
    display: flex;
    flex-direction: column;
    gap: 17px;
}
.woocommerce-variation-add-to-cart{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media (max-width:576px) {
        flex-direction: column;
    }
}

.variations{
    label{
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        position: relative;
        color: $color-white;
        bottom: -10px;
    }
    select {
        border-radius: .56rem !important;
        background-color: white !important;
    }
}
.variations-total-price{
    font-size: 18px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    font-weight: 600;
    margin-bottom: -63px;
    .update-price-container{
        font-weight: 400;
    }

    @media (max-width:576px) {
        margin-bottom: 0;
    }
}
.woocommerce-variation-price{
    display: none !important;
}

//checkoutWC
span.cfw-sc-opac,
div.cfw-sc-modal {
    display: none !important;
    visibility: hidden !important;
}

#cfw-side-cart-floating-button .cfw-side-cart-floating-button-anchor {
    box-shadow: none !important;
    border: 1px solid $color-black;
    border-radius: .56rem;
    padding: .383rem .667rem .333rem !important;
}

#cfw-side-cart-floating-button:hover .cfw-side-cart-floating-button-anchor {
    color: $color-white !important;
    background-color: $color-black !important;
    border-color: $color-white !important;
}

#cfw-side-cart-floating-button:hover .cfw-side-cart-quantity-wrap {
    opacity: 1 !important;
    color: white !important;
}

#cfw-side-cart-floating-button .cfw-side-cart-quantity-wrap svg rect {
    fill: transparent !important;
}

#cfw-side-cart-floating-button:hover .cfw-side-cart-quantity-wrap svg path {
    fill: white;
}