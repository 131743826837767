//
// Footer Styles
// --------------------------------------------------
#cta-banner {
	background-color: $color-black;
	color: $color-white;
	padding: .5rem 0;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	transition: all .1s ease-out;
	border-top: 1px solid $color-white;
	z-index: 2;

	&.is-close {
		transform: translateY(100%);
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;

		h2 {
			line-height: normal;
			
			@media (max-width: 1199px) {
				font-size: $large;
			}

			@media (max-width:991px) {
				font-size: $big;
			}

			@media (max-width:767px) {
				font-size: 1.2rem;
			}
		}

		.btn-primary {
			white-space: nowrap;
		}

		@media (max-width:1199px) {
			padding-right: $medium;
		}
	}

	.btn-close-banner {
		position: absolute;
		top: 50%;
		right: 3rem;
		padding: 0;
		background: transparent;
		border: none;
		color: $color-white;
		transform: translateY(-50%);

		@media (max-width:1701px) {
			right: 2rem;
		}
	}

	@media (max-width:767px) {
		display: none !important;
		visibility: hidden !important;
	}
}
#popup-book-treatment {
	right: 0;
	bottom: 0;
	top: initial;
	display: flex;
	align-items: center;
	transition: all .2s linear;
	opacity: 0;
	visibility: hidden;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: -1;
		background-color: $color-black;
		opacity: .5;
	}

	
	&.is-open {
		bottom: 0;
		opacity: 1;
		visibility: visible;
	}

	.popup-container {
		height: fit-content;
		margin: 0 auto;
		background-color: $color-white;
		box-shadow: -2px 2px 13px 0px rgba(0, 0, 0, 0.25);
		overflow: hidden;

		@media (max-width:991px) {
			max-height: 90vh;
			overflow-y: scroll;
		}
		@media (max-width:767px) {
			width: 90vw;
		}

		@media (max-width:576px) {
			max-height: 90vh;
			width: 90vw;
		}
	}

	.btn-close-popup {
		font-size: $large;
		padding: 0;
		background-color: transparent;
		color: $color-black;
		border: none;
		margin-left: auto;
		margin-bottom: 1rem;
	}

	h2.massive {
		text-transform: none;
		margin-bottom: $massive;
		margin-bottom: 20px;
	}

	.popup-offers-link {
		margin: 0 0 20px 0;
		display: inline-block;
		color: $color-black;
		font-weight: 600;
	}

	.booking-type {
		margin-bottom: $large;

		input {
			opacity: 0;
			margin: 0;
			margin-right: 0;
		}

		label {
			padding-left: 5px;
			font-weight: 400;
			position: relative;
			cursor: pointer;
			left: initial;
			top: initial;
			transition: all .2s linear;
			display: flex;
			justify-content: flex-start;
			gap: 5px;

			&::before {
				display: inline-block;
				content: "";
				min-width: $small;
				min-height: $small;
				max-width: $small;
				max-height: $small;
				border-radius: 50%;
				border: 2px solid $color-text;
				background-color: transparent;
				transition: all .2s linear;
			}
		}

		input:checked~label {

			&::before {
				background-color: $color-text !important;

			}
		}
	}

	select {
		background-color: transparent;
		margin-bottom: $massive;
		height: $massive;
		padding: .5rem 1rem;
		cursor: pointer;

		&.is-active {
			color: $color-white;
			background-color: $color-black;
		}

		@media (max-width: 576px) {
			margin-bottom: $large;
		}
	}

	h3.big {
		margin-bottom: $medium;
	}

	.loader-content {

		.m-anim {
			margin-top: 0;
			margin-bottom: 0;
			width: 2.5rem;
			height: 2.5rem;
		}
	}

	#formContact,
	#formBooking,
	#clinicsInfos {
		display: none;

		&.is-visible {
			display: initial;
		}
	}

	.btn-primary {
		margin-left: auto;
	}

	#clinicsInfos {

		.infos-wrapper {

			a {
				color: $color-text;
				&:hover{
					color: $color-gray-light;
			}
			}
		}
	}

	#formContact {

		select {
			margin: 0;
		}

		input[type="submit"] {
			margin-left: auto;
		}

		.gfield--type-select {

			label {
				display: inline-block;
				position: static;
				margin-bottom: $medium;
				font-size: $big; //24px
				line-height: 1.6rem;
				font-family: $font-title;
				font-weight: 500;
				transform: none !important;
			}
		}
	}
}

#footer {
	background-color: $color-white;

	#footer-top {
		padding-top: 7rem;

		@media (max-width:767px) {
			padding-top: 2.222rem;
		}
		
		a {
			color: $color-black;
		}

		.grid {
			display: grid;
			grid-template-columns: 25% 25% 33% 17%;
			gap: $big;

			@media (max-width:991px) {
				grid-template-columns: repeat(2, 1fr);
				gap: $massive;
			}

			@media (max-width:767px) {
				grid-template-columns: 1fr;
				gap: $huge;
			}
		}

		.contact {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			img {
				width: 100%;
				max-width: 6.9rem;
				margin-bottom: $huge;
			}

			.btn-primary {
				margin-top: $big;

				@media (max-width:576px) {
					width: fit-content;
				}
			}
		}

		.footer-nav {
			font-family: $font-title;
			font-size: $big;
			line-height: normal;
			margin-top: auto;
		
			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-end;
				gap: 1rem;

				li {
					&:hover {

						a {
							opacity: .5;
						}
					}
				}
			}
		}

		.newsletter {
			width: 100%;
			max-width: 22.222rem;
			
			@media (max-width:767px) {
				max-width: 100%;
			}

			p {
				margin-top: $small;
			}

			.btn-primary {
				margin-top: $large;
			}
		}

		.socials {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-start;
			gap: $small;
			margin-right: 4rem;

			@media (max-width:991px) {
				flex-direction: row;
				margin-right: 0;
			}
			
			@media (max-width:767px) {
				align-items: flex-start;
				flex-wrap: wrap;
			}

			li {
				width: 100%;
				max-width: 40px;
			}

			.btn.icon {
				font-size: $regular;
				width: 100%;
				max-width: 40px;
				color: $color-white;

				a {
					color: inherit;
				}

				&:hover {
					color: $color-black;
				}

				&::after {
					font-size: inherit;
					font-family: 'Font Awesome 6 Brands';
				}

				&.instagram {
					&::after {
						content: "\f16d";
					}
				}

				&.facebook {
					&::after {
						content: "\f39e";
					}
				}

				&.tiktok {
					&::after {
						content: "\e07b";
					}
				}

				&.linkedin {
					&::after {
						content: "\f0e1";
					}
				}

				&.youtube {
					&::after {
						content: "\f167";
					}
				}

				&.spotify {
					&::after {
						content: "\f1bc";
					}
				}
			}
		}
	}

	#footer-bottom {
		margin-top: 6.7rem;
		background-color: $color-black;
		padding: .5rem 0;
		color: $color-white;
		font-size: .75rem;
		line-height: normal;

		@media (max-width:767px) {
			font-size: 0.72rem;
			line-height: normal;
			margin-top: $large;
		}
		
		a {
			color: inherit;

			&:hover {
				opacity: .8;
			}
		}

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 1rem;

			@media (max-width:991px) {
				flex-wrap: wrap;

				.payments-method {
					order: 1;
					width: 100%;
				}

				.copyright {
					order: 2;
				}

				.conditions {
					order: 3;
				}

				.signature {
					order: 4;
				}
			}

			@media (max-width:767px) {
				gap: $big;
			}
		}

		.payments-method {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: $small;

			img {
				height: 29px;
				width: auto;

				@media (max-width:767px) {
					height: 25px;
				}
			}
		}

		.signature {
			white-space: nowrap;
		}
	}
}
