

// Base

html,
body {
  overflow-x: hidden !important;
  font-size: $regular; //18px
  line-height: 1.333rem;
  scroll-behavior: smooth;

  @media (max-width:767px) {
    font-size: $small;
    line-height: normal;
  }
}

body.single-treatment {
  overflow: unset !important;

  @media (max-width:767px) {
    overflow: hidden !important;
  }
}

p, h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.container {
  width: 90vw;
  max-width: 1920px;
  margin: 0 auto;
  
  @media (max-width:1921px) {
    width: 100vw;
    max-width: 90vw;
  }

  @media (max-width:576px) {
    max-width: 100%;
    padding: 0 $medium;
  }
}

.small-container {
  width: 100%;
  max-width: 50%;
  margin: 0 auto;

  @media (max-width:1441px) {
    max-width: 80%;
  }

  @media (max-width:1199px) {
    max-width: 100%;
  }
}

.page-title{
  padding: 40px 0 80px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px){
    flex-direction: column;
    font-size: 2rem;
    padding: 0 0 32px;
  }
}

.section-title{
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid $color-black;

  @media (max-width: 767px){
    flex-direction: column;
  }
}

#breadcrumb{
  font-family: $font-title;
  font-style: normal;
  font-weight: 500;
  font-size: $small;
  line-height: normal;
  color: $color-gray-medium;
  margin-bottom: 0;

  a{
      color: $color-gray-medium;
  }
  span {
     padding: 0 .75rem;

     &:first-child {
      padding-left: 0;
     }

     &.breadcrumb_last {
      color: $color-black;
     }
  }
  @media (max-width: 767) {
    font-size: 0.625rem;
  }
}

.small:not(input, select, textarea) {
  font-size: $small; //16px
  line-height: normal;
  font-weight: normal;

  @media (max-width: 991px) {
    font-size:$regular-mobile;
    line-height: normal;
  }
}

.regular,
p {
  font-size: $regular; //18px
  line-height: 1.333rem;
  font-family: $font-base;
  font-weight: 400;
  margin: 0;

  @media (max-width: 991px) {
    font-size: $small;
    line-height: normal;
  }

  &.bold {
    font-weight: 500;
    font-family: $font-title;
  }
}

p {
  margin: $regular 0;

  &:first-child {
    margin-top: 0;
  }
}

.medium,
h6 {
  font-size: $medium; //20px
  line-height: 1.222rem;
  font-family: $font-title;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: $regular;
    line-height: normal;
  }
}

.big,
h5 {
  font-size: $big; //24px
  line-height: 1.6rem;
  font-family: $font-title;
  font-weight: 500;

  @media (max-width:991px) {
    font-size: $medium;
    line-height: normal;
  }
}

.large:not(input, select, textarea),
h4 {
  font-size: $large; //30px
  line-height: normal;
  font-family: $font-title;
  font-weight: 500;

  @media (max-width:991px) {
    font-size: $big;
    line-height: normal;
  }
}

.massive,
h3 {
  font-size: $massive; //46px
  line-height: 2.833rem;
  font-family: $font-title;
  font-weight: normal;

  @media (max-width: 991px) {
    font-size: $large;
    line-height: normal;
  }
}

.huge,
h2 {
  font-size: $huge; //56px
  line-height: normal;
  font-family: $font-title;
  font-weight: 900;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: $massive;
    line-height: normal;
  }

  @media (max-width: 767px) {
    font-size: $huge-mobile;
    line-height: normal;
  }
}

.gigantic,
h1 {
  font-size: $gigantic; //80px
  line-height: normal;
  font-family: $font-title;
  font-weight: 900;

  @media (max-width: 991px) {
    font-size: $huge;
    line-height: normal;
  }
}

.small-h1{
  font-size: 2.875rem;
  line-height: 3.44375rem;
  font-family: $font-title;
  font-weight: 700;
}

.small-title {
  font-size: $gigantic-small;
  line-height: 3.056rem;
  font-family: $font-title;
  font-weight: 700;
}

.big-title {
  font-size: 9.778rem;
  line-height: 8.389rem;
  font-family: $font-title;
  font-weight: 900; 
  @media (max-width:576px) {
    font-size: 6rem;
    line-height: 5.063rem;
  }
}

.btn-primary {
  padding: .383rem .667rem .333rem !important;
  border: 1px solid $color-black !important;
  border-radius: .56rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  text-align: center;
  font-size: $regular;
  line-height: normal !important;
  font-family: $font-title;
  font-weight: 500;
  background-color: $color-white !important;
  color: $color-black !important;
  transition: $transition-base;

  &:hover,
  &.reverse {
    color: $color-white !important;
    background-color: $color-black !important;
    border-color: $color-white !important;
  }

  &.reverse {
    &:hover {
      color: $color-black !important;
      background-color: $color-white !important;
      border-color: $color-black !important;
    }
  }

  &.aqua {
    color: $color-aqua !important;
    border-color: $color-aqua !important;

    &:hover {
      background-color: $color-aqua !important;
      color: $color-black !important;
    }
  }

  &.green {
    color: $color-green !important;
    border-color: $color-green !important;

    &:hover {
      background-color: $color-green !important;
      color: $color-black !important;
    }
  }

  &.purple {
    color: $color-purple !important;
    border-color: $color-purple !important;

    &:hover {
      background-color: $color-purple !important;
      color: $color-black !important;
    }
  }
  
  @media (max-width: 991px) {
    font-size: $small;
    line-height: normal;
    padding: 0.5rem 0.667rem 0.5rem;
  }

  @media (max-width:576px) {
    width: 100% !important;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  font-family: $font-title;
  font-size: $regular;
  font-weight: 500;
  line-height: normal;
  color: $color-black;
  transition: $transition-base;

  &.icon {
    color: $color-white;
    background-color: $color-black;
    border-radius: .56rem;
    border: 1px solid $color-black;
    justify-content: center;
    padding: 0.333rem 0.556rem;
    
    &::after {
      font-family: $fontawesome-solid;
      color: inherit;
      font-size: $small;
    }

    &:hover {
      background-color: $color-white;
      color: $color-black;
    }
  }

  &.external {
    gap: .56rem;
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;

    &::before {
      content: "\f08e";
      font-family: $fontawesome-solid;
    }

    &:hover {
      background-color: transparent;
      color: $color-black;
    }
  }

  @media (max-width: 991px) {
    font-size: $small;
    line-height: normal;
  }
}

.btn-icon {
  color: $color-white;
  background-color: $color-black;
  padding: .333rem 0.667rem;
  border: 1px solid $color-black;
  border-radius: .56rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  text-align: center;
  font-size: $regular;
  line-height: normal;
  font-family: $font-title;
  font-weight: 500;
  background-color: $color-white;
  color: $color-black;
  transition: $transition-base;

  i {
    color: inherit;
    font-size: inherit;
  }

  &:hover {
    color: $color-white;
    background-color: $color-black;

    i {
      color: inherit;
    }
  }

  @media (max-width: 991px) {
    font-size: $small;
    line-height: normal;
  }
}

.hidden{
  display: none !important;
}

.loader-content {
  display: none;
  
  .m-anim {
    display: block;
    width: 5.5rem;
    height: 5.5rem;
    margin: 2rem auto;
    margin-top: 6rem;
    position: relative;
    box-sizing: border-box;
  }
}

.filters{
  display: flex;
  max-width: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
  list-style: none;

  li{
    color: $color-black;
    opacity: .3;
    transition: all .2s linear;
    cursor: pointer;

    &:hover, &.is-active{
      opacity: 1;
    }
  }
}

.m-anim {
  
  svg {
    width: 100% !important;
    height: auto !important;
  }

  &.green {

    svg {
      path {
        stroke: $color-green;
      }
    }
  }

  &.black {

    svg {
      path {
        stroke: $color-black
      }
    }
  }

  &.purple {

    svg {
      path {
        stroke: $color-purple;
      }
    }
  }

  &.white {

    svg {
      path {
        stroke: $color-white;
      }
    }
  }

  &.aqua {

    svg {
      path {
        stroke: $color-aqua;
      }
    }
  }
}

.product-block {
  display: flex !important;
  gap: .5rem;
  text-align: left;
  color: $color-black;
  flex-direction: column;
  justify-content: space-between;

  .img {
      position: relative;
      width: 100%;
      height: 17rem;
      max-height: 15.5rem;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-purple;
        z-index: 1;
        transform: scaleY(0);
        transition: transform .4s ease-in-out;
        transform-origin: bottom;
        opacity: .6;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  }

  p {
    margin-bottom: .5rem;
  }

  h2 {
    margin-top: 0;
    text-transform: none;
    margin-bottom: $medium;
  }

  .price-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 1rem;

    &.is-onsale {

      .from {
        display: none;
      }

      .price {
        display: flex;
        flex-direction: column;
        gap: .2rem;

        del {
          font-size: 14px;
          line-height: normal;
        }

        ins {
          text-decoration: none;
        }
      }
    }
  }

  &:hover {

    .img {
        &::before {
            transform: scaleY(1);
            transform-origin: top;
        }
    }
  }
}

.discount-display {
  padding: 2px 7px;
  background-color: $color-green;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: $color-black !important;
}