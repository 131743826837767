//
// Header Styles
// --------------------------------------------------

.btn-offers {
	position: fixed;
	top: 12rem;
	left: calc(100% - 74px);
	z-index: 19;
	background-color: $color-black;
	color: $color-white;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 0.267rem 0 .267rem;
	border: 1px solid $color-white;
	border-right: none;
	white-space: nowrap;

	img{
		padding: 0 0.967rem 0 .267rem;
		width: auto;
	}

	&:hover {
		right: 0;
		left: initial;
	}

	@media (max-width:767px) {
		display: none;
	}
}

body {
	&.single-offer,
	&.single-treatment,
	&.single-news,
	&.single-clinic,
	&.single-product,
	&.single-team,
	&.single-job {

		#header {

			&::after {
				display: none;
				visibility: hidden;
			}
		}
	}
}

#header {
	width: 100%;
	background-color: $color-white;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	transition: all 0.4s linear;
	padding-top: 5.7rem;
	padding-bottom: 1.9rem;

	&.black {
		background-color: $color-black;

		#logo {

			img {
				display: none;
				visibility: hidden;

				&.white {
					display: block;
					visibility: visible;
				}
			}
		}

		#mobile-menu-trigger {

			.trigger-bar {
				background-color: $color-white;
			}
		}
	}

	&.border-bottom {
		&::after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: 0;
			width: 90vw;
			max-width: 1920px;
			height: 2px;
			background-color: $color-black;
			transform: translateX(-50%);
		}
	}
	
	@media (max-width:767px) {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: 0;
			width: 100%;
			max-width: 90vw;
			height: 2px;
			background-color: $color-black;
			transform: translateX(-50%);
		}
	}

	&.sticky {
		padding-top: $medium;
		padding-bottom: $small;
		#header-extras {
			opacity: 0;
			margin-top: -40px;
		}

		@media (max-width:767px) {
			.mobile-nav {
				top: 63px;
				min-height: calc(100vh - 63px);
    		max-height: calc(100vh - 63px);
			}
		}

		#menu {

			#menu-main-container {

				ul {

					li {
						
						.sub-menu {
							top: 71px !important;
    					padding-top: 20px !important;
						}

						&.menu-item-has-children {
							&:hover {

								.sub-menu {
									@media (max-width:1199px) {
										transform: translateY(-17px) !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	#mobile-menu-trigger {
		display: none;
		visibility: hidden;
		padding: 0;
		border: none;
		background-color: transparent;

		&:hover {
			background-color: transparent;
			border: none;
		}

		&.is-active {

			.trigger-bar {
				&:nth-child(2) {
					opacity: 0;
					visibility: hidden;
				}

				&:first-child {
					transform: rotate(45deg) translateY(50%);
    			transform-origin: top left;
					top: -1px;
				}

				&:last-child {
					transform: rotate(-45deg) translateY(50%);
    			transform-origin: bottom left;
					bottom: -2px;
				}
			}
		}

		.trigger-bar {
			display: block;
			width: 2.2rem;
			height: 3px;
			transition: all 0.2s linear;
			position: relative;
			background-color: $color-black;
			&:not(:first-child) {
				margin-top: 7px;
			}
		}

		@media (max-width:767px) {
			display: inline-block;
			visibility: visible;
		}
	}

	#header-content {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	#logo {
		width: 100%;
		max-width: 21.4rem;
		transition: $transition-base;
		a {
			display: block;
		}

		img {
			&.white {
				display: none;
				visibility: hidden;
			}
		}

		@media (max-width:1499px) {
			max-width: 10rem;
		}

		@media (max-width:1199px) {
			max-width: 14rem;
		}

		@media (max-width:991px) {
			max-width: 8rem;
		}

		@media (max-width:767px) {
			max-width: 15rem;
		}
	}

	#menu {
		transition: all 0.4s linear;

		#menu-main-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 2.1rem;
			width: 100%;

			.bottom-part {
				display: none;
				visibility: hidden;

				@media (max-width:767px) {
					display: block;
					visibility: visible;

					.btn-primary {
						display: block;
						width: 100%;
						visibility: visible;
					}
				}
			}

			ul:not(.submenu-nav) {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				display: flex;
				align-items: flex-start;
				justify-content: flex-end;
				gap: 2.1rem;

				@media (max-width:1499px) {
					gap: 1rem;
				}

				// @media (min-width:991px) {
				// 	.sub-menu.second-level-container {
				// 		display: none;
				// 		visibility: hidden;
				// 	}
				// }

				li {
					position: relative;
					a {
						font-size: $regular;
						font-family: $font-title;
						font-weight: 500;
						display: block;
						color: $color-text;

						@media (max-width:1199px) {
							font-size: $small;
						}
					}
					&:last-child {
						margin-right: 0;
					}

					&:hover {

						>a {
							opacity: .5;
						}
					}

					&#mega-menu {

						a {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: .333rem;

							&::before {
								content: "\f0c9";
								font-family: $fontawesome-regular;
								font-size: .8rem;
								color: inherit;

								@media (max-width:767px) {
									display: none;
									visibility: hidden;
								}
							}
						}
					}
					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						> a {
							color: $color-text;
						}
					}
					.sub-menu {
						padding-top: 40px;
						position: fixed;
						left: 0;
						top: 124px;
						width: 100vw;
						color: $color-white;
						text-align: left;
					  transform: 0;
						transition: all .2s ease-in-out;
						z-index: 10;
						overflow: hidden;
						opacity: 0;
						height: 0;
						visibility: hidden;

						@media (max-width:767px) {
							display: none;
							visibility: hidden;
						}

						.sub-menu-container {
							width: 100%;
							background-color: $color-black;
							padding: $massive 0;
						}

						li {
							white-space: nowrap;
							margin: 0;
							&:last-child {
								margin-bottom: 0;
							}
							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: inherit;
								}
							}

							a {
								color: inherit;
							}
						}

						&.pages,
						&.mix {

							ul {
								display: grid;
								grid-template-columns: repeat(4, 1fr);
								gap: $massive;
								width: calc(100% - 1.333rem);

								@media (max-width:1199px) {
									grid-template-columns: repeat(3, 1fr);
								}

								@media (max-width:991px) {
									grid-template-columns: repeat(2, 1fr);
								}

								li {
									white-space: initial;
								}
							}

							.page-block {
								color: $color-white;
								text-align: left;

								.img {
									position: relative;
									margin-bottom: .45rem;
									display: flex;

									&::before {
										content: "";
										position: absolute;
										left: 0;
										top: 0;
										width: 100%;
										height: 100%;
										background-color: $color-green;
										z-index: 1;
										transform: scaleY(0);
										transition: transform .4s ease-in-out;
										transform-origin: bottom;
										opacity: .6;
									}
								}

								.excerpt {
									margin-top: .45rem;
								}

								&:hover {
									opacity: 1 !important;

									.img {
										&::before {
											transform: scaleY(1);
											transform-origin: top;
									 	}
									}
								}
							}

							.offer-block {
								color: $color-black !important;
								height: 100%;
								
								.content {
									background-color: $color-white !important;
								}

								&:hover {
									color: $color-white !important;
									opacity: 1 !important;

									.content {
										border-color: $color-white;
										background-color: $color-black !important;
									}
								}
							}
						}

						&.offers {

							.btn-primary {
								margin-left: auto;
								margin-top: 2.5rem;
							}

							ul {
								display: grid;
								grid-template-columns: repeat(3, 1fr);
								gap: $massive;
								width: calc(100% - 1.333rem);
								align-items: stretch;

								@media (max-width:1199px) {
									grid-template-columns: repeat(2, 1fr);
								}
							}

							.offer-block {
								color: $color-black !important;
								height: 100%;
								
								.content {
									background-color: $color-white !important;
								}

								&:hover {
									color: $color-white !important;
									opacity: 1 !important;

									.content {
										border-color: $color-white;
										background-color: $color-black !important;
									}
								}
							}
						}
					}
					@media (min-width: 767px) {
						&.menu-item-has-children {

							>a {
								display: flex;
								align-items: center;
								justify-content: flex-start;
								gap: .3rem;

								&::after {
									content: "\f063";
									font-family: $fontawesome-regular;
									color: inherit;
									font-size: .75rem;
									line-height: normal;
									transition: $transition-base;
								}
							}

							&#mega-menu {

								>a {
									&::after {
										display: none;
									}
								}
							}
	
							&:hover {

								>a {
									&::after {
										transform: rotate(-180deg);
									}
								}
	
								.sub-menu {
									visibility: visible;
									opacity: 1;
									height: auto;
									transform: translateY(-13px);
								}
							}
						}
					}
				}
			}

			.btn-primary.reverse {
				@media (max-width:1199px) {
					display: none !important;
					visibility: hidden;
				}
			}
		}
	}

	#header-extras {
		position: absolute;
		top: 0;
		right: 0;
		width: 100vw;
		transition: all 0.4s linear;
		background-color: $color-black;
		color: white;
		font-size: $small;
		font-family: $font-title;
		font-weight: 500;
		padding: .45rem 0;

		.extras {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.global-search {
			display: flex !important;
			align-items: flex-end;
			justify-content: flex-start;
			gap: .8rem;
			position: relative;
			overflow: hidden;

			.founded-posts {
				position: absolute;
				left: -2.5%;
				top: 100%;
				width: calc(105%);
				background-color: black;
				color: white;
				padding: $medium;
				padding-top: 5px;
				display: none;
				z-index: 1;

				.loader-content {

					.m-anim {
						margin: 0 auto;
						width: 3rem;
						height: 3rem;
					}
				}

				p.small {
					margin-top: $small;
					font-weight: 600;
				}

				.btn-primary {
					width: 100%;
					margin-top: 5px;
				}

				.search-block {
					padding: $small 0;
					border-color: white;

					.img {
						max-width: 25%;
						min-width: 25%;
						max-height: 6rem;
					}

					h3 {
						font-size: $small !important;
						line-height: normal !important;
						margin: 0 !important;
					}

					p {
						display: none;
					}

					.btn {
						display: none;
					}
				}
			}

			&:hover {
				opacity: 1 !important;
			}

			button:not(.btn) {
				padding-left: 0 !important;
				padding-right: 0 !important;
				background-color: transparent;
				color: white;
				border: none;
			}

			form {
				width: 0;
				transform-origin: right;
				transform: scaleX(0);
				overflow: hidden;
				transition:  all .2s linear;
				display: flex;
				align-items: center;
				gap: .8rem;
				margin: 0;

				input {
					border: none !important;
					padding: 0 !important;
					color: white;
					min-width: 17rem;
					font-size: 14px;

					@media (max-width:1199px) {
						min-width: 12rem;
					}

					&:focus {
						border: none !important;
					}
				}
			}

			&.is-open {
				border-bottom: 1px solid white;
				overflow: visible;

				form {
					width: auto;
					transform: scaleX(1);
					overflow: visible;
				}
			}
		}

		@media (max-width:767px) {
			display: none;
			visibility: hidden;
		}

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		a {
			color: inherit;
		}

		.extra {
			display: inline-block;
			margin-right: 2.1rem;

			&:nth-last-child(2) {
				margin-right: 0;
			}

			&:last-child {
				margin-right: 0;
				margin-left: 2.1rem;
			}
			&#language-switcher {
				margin-left: 7.8rem;
				
				&.list {
	
					.current {
						&::after {
							content: "\f063";
							font-family: 'Font Awesome 6 Regular';
							font-weight: 400;
							font-size: .75rem;
							color: inherit;
							transition: all .2s linear;
						}
					}
				}
			}

			&:hover:not(.cart):not(#language-switcher) {
				opacity: .6;
			}
		}

		.account-icon{
			width: 16px;
    		vertical-align: middle;
		}

		#header-cart {
			i {
				color: #ffffff;
				margin-right: 6px;
			}
		}
	}

	.mobile-nav {

		@media (max-width:767px) {
			position: fixed;
			width: 100vw !important;
			height: calc(100vh - 68px);
			max-height: calc(100vdh - 68px);
			overflow-y: scroll;
			overflow-x: hidden;
			color: white !important;
			background: black;
			right: -100vw;
			top: 68px;
			z-index: 1;
			display: block !important;
			visibility: hidden;
			transform-origin: right;
			transition: all .2s linear;
			padding: $large 0;

			&.is-open {
				right: 0;
				visibility: visible;
			}

			#main-menu {
				height: calc(100vh - 350px - 3.111rem);
				max-height: calc(100dvh - 350px - 3.111rem);

				&.is-hidden {
					height: auto;
					overflow: visible;
				}
			}

			li {
				&.first-level {
					font-size: $big;
  				line-height: 2rem;
					border-top: 1px solid $color-white;
					transition: all .2s linear;

					&:first-of-type {
						border-top: none;
					}

					a {
						padding: $big 0;
						z-index: 2;
					}

					&.is-hidden {
						opacity: 0;
						visibility: hidden;
					}
				}

				&.menu-item-has-children {
					display: flex;
					align-items: center;
					justify-content: space-between;
					position: relative;

					&::after {
						content: "\f061";
						font-family: $fontawesome-regular;
						font-weight: 400;
						color: inherit;
						font-size: $medium;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						z-index: 1;
					}
				}

				&.second-level {
					font-size: $medium !important;
					line-height: 1.222rem !important;
				}
			}

			a:not(.btn-primary) {
				color: inherit !important;
				font-size: inherit !important;
				width: 100%;
				display: block;
			}

			.first-level-container {
				
				&.container {
					margin: 0 auto !important;
					margin-bottom: $huge !important;
					display: block !important;
					text-align: left !important;
					padding: 0 $medium !important;

					/* largeur poignée */
					&::-webkit-scrollbar {
						width: 6px;

					}
					
					/* background */
					&::-webkit-scrollbar-track {
						background: transparent;
					}
					
					/* Poignée (thumb) */
					&::-webkit-scrollbar-thumb {
						background: $color-white;
						transition: all .2s linear;
						border-radius: 4px;
					}
					
					&::-webkit-scrollbar-thumb:hover {
						background: $color-white;

						//Firefox + Edge
						scrollbar-color: transparent $color-white;
					}
				}
			}

			.parent-level-container {
				overflow-y: scroll;
				overflow-x: hidden;
			}

			.second-level-container,
			.third-level-container {
				position: absolute;
				left: 200%;
				top: 0;
				width: 100%;
				height: calc(100vh - 68px);
				max-height: calc(100vdh - 68px);
				padding-bottom: $large;
				overflow-y: scroll;
				background-color: $color-black;
				z-index: 3;
				transition: all .2s linear;

				&.is-active {
					left: 0;

					&.is-hidden {

						.second-level {
							opacity: 0;
							visibility: hidden;
						}

						.btn-primary {
							opacity: 0;
							visibility: hidden;
						}
					}
				}

				.back-level {
					padding: 0;
					border: none;
					background-color: transparent;
					color: $color-white;
					margin-bottom: $big;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: .5rem;
					font-family: $font-base;

					&::before {
						content: "\f060";
						font-family: $fontawesome-regular;
						font-weight: 400;
						color: inherit;
						font-size: .7rem;
					}
				}

				&.offers,
				&.mix {

					ul {
						flex-direction: column;
						gap: $big;
					}

					.offer-block {
						color: $color-black !important;
						
						.content {
							background-color: $color-white !important;
						}

						&:hover {
							color: $color-white !important;

							.content {
								border-color: $color-white;
								background-color: $color-black !important;
							}
						}
					}

					.page-block {
						text-align: left;

						.excerpt {
							display: none;
							visibility: hidden;
						}

						.title {
							font-size: inherit !important;
							line-height: inherit !important;
							margin-top: .4rem;
						}
					}
				}

				&.pages {

					ul {
						display: grid !important;
						grid-template-columns: repeat(2, 1fr);
						row-gap: $big !important;
						column-gap: $small !important;
					}

					.page-block {
						text-align: left;

						.excerpt {
							display: none;
							visibility: hidden;
						}

						.title {
							font-size: inherit !important;
							line-height: inherit !important;
							margin-top: .4rem;
						}
					}
				}

				.treatments-nav {

					.btn-primary {
						color: $color-black !important;
						width: 100%;

						&:hover {
							color: $color-white !important;
						}
					}

					ul {
						padding: 0;
						margin: 0;
						list-style: none;
						position: relative;

						&.type-nav {
							flex-direction: column;
							align-items: flex-start !important;
							justify-content: flex-start !important;
							margin-top: $medium !important;
							margin-bottom: 1rem !important;
							gap: 0 !important;
	
							li {
								width: 100%;
								text-align: left;
								border: none;
								margin-bottom: $small;
	
								&:last-child {
									margin-bottom: 0;
								}
	
								a {
									padding: 0;
									font-size: $small !important;
									line-height: normal;
								}
							}
						}
					}

					.second-level {
						font-size: $big !important;
						line-height: 2rem !important;
						border-top: 1px solid $color-white;

						&:first-child {
							border-top: none;
						}

						a {
							padding: $big 0;
							z-index: 2;
						}
	
						&.is-hidden {
							opacity: 0;
							visibility: hidden;
						}
					}
				}
			}

			.third-level-container {
				top: -42px;

				ul.regular {
					flex-direction: column;
					justify-content: flex-start !important;
					align-items: flex-start !important;
					gap: 0 !important;
					margin-top: $big !important;

					li {
						width: 100%;
						text-align: left;
					}

					li:not(:last-child) {
						margin-bottom: 1rem;
					}

					a {
						font-size: $regular !important;
					}
				}
			}

			.bottom-part {
				font-family: $font-title;
				font-size: $small;
				line-height: normal;

				&.is-hidden {
					display: none !important;
					visibility: hidden !important;
				}
				
				.container {
					display: flex !important;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-end;
				}

				a {
					display: inline-block;
					width: fit-content;
				}

				.extra:not(:first-child) {
					margin-top: 1rem;
				}

				.account-icon{
					img{
						width: 16px;
						left: -0.5px;
						bottom: -2px;
						position: relative;
					}
				}

				.btn-primary {
					margin-top: $big;
					color: $color-black !important;
					
					&:hover {
						color: $color-white !important;
					}
				}

				.bottom {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					justify-content: flex-start;
					margin-top: $gigantic;
					width: 100%;

					.extra {
						border: 1px solid $color-white;
						border-radius: 0.55rem;
						padding: 0.39rem 0.67rem;
						margin: 0;
						margin-right: $regular;

						&#language-switcher {
							padding: .09rem 0;

							.languages-list {
								border-radius: 0;
								border-top-left-radius: .56rem;
								border-top-right-radius: .56rem;
								display: block !important;
								bottom: calc(100% + 1px);

								li {
									text-align: left;
	
									a {
										color: $color-white !important;
										display: block !important;
										width: 100% !important;
									}
	
									&:hover {
										
										a {
											color: $color-black !important;
										}
									}
								}
							}
						}

						&:last-child {
							margin-right: 0;
						}

						&:hover:not(#language-switcher) {
							color: $color-black !important;
							background-color: $color-white;
						}
					}
				}
			}
		}
	}
}